var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure"},[_vm._m(0),_c('div',{staticClass:"structure_c"},[_c('div',{staticClass:"structure_frame"},[_c('div',{staticClass:"structure_frame_head"},[_c('div',{staticClass:"structure_nav"},[_c('el-badge',{staticClass:"item",attrs:{"is-dot":_vm.isHot}},[_c('div',{staticClass:"structure_nav_s",style:({
                color: _vm.selectIndex == 1 ? '#0058FF' : '#1A1A1A',
              }),on:{"click":function($event){return _vm.switchTab(1)}}},[_vm._v(" 待审批 ")])]),_c('dive',{staticClass:"structure_nav_s",style:({
              color: _vm.selectIndex == 2 ? '#0058FF' : '#1A1A1A',
            }),on:{"click":function($event){return _vm.switchTab(2)}}},[_vm._v(" 已审批 ")])],1)])]),_c('div',{staticClass:"structure_cstretch"},[(_vm.selectIndex == 1)?_c('Wait'):_vm._e(),(_vm.selectIndex == 2)?_c('Complete'):_vm._e()],1)]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure_t"},[_vm._v("审批管理 / "),_c('span',[_vm._v("事件审批")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filing"},[_vm._v(" CopyRight@2023广州巨源信息技术有限公司-"),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("粤ICP备2023127373号-4")])])
}]

export { render, staticRenderFns }