<template>
  <div class="group">
    <div class="group_head">
      <span style="color: #b2b2b2">考勤管理 /</span>
      <span style="color: #b2b2b2"> 考勤组管理 /</span>
      <span style="color: #5c5c5c">
        {{ isEdit ? "编辑考勤组" : "新建考勤组" }}
      </span>
    </div>
    <div class="group_wrapper">
      <div class="group_search_wrapper">
        <span @click="backGroup" style="cursor: pointer">
          <img src="@/assets/returnback.png" class="title_icon" />
        </span>
        <span class="group_back" @click="backGroup" style="cursor: pointer"
          >返回
        </span>
        <span class="group_title"
          >&nbsp;&nbsp;<span style="color: #cfd4e8">|</span>&nbsp;&nbsp;{{
            isEdit ? "编辑考勤组" : "新建考勤组"
          }}</span
        >
      </div>
      <div class="group_wrapper">
        <div class="group_form_wrapper">
          <div class="form_title">规则设置</div>
          <el-form :model="groupForm" ref="groupForm" class="groupForm">
            <el-form-item label="考勤组名称" class="groupForm_label">
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
            <el-form-item
              label="打卡人员"
              class="groupForm_label"
              min-width="413px"
            >
              <div class="record_search_item">
                <div v-if="isFiltrate" @click="openRange">
                  <el-popover placement="top" trigger="hover" ref="refpopover">
                    <div class="filtrate">
                      <div class="filtrate_single_p">
                        <div
                          class="filtrate_single_c"
                          v-for="(item, index) in omitDisplay(
                            copyfiltrateResult
                          )"
                          :key="index"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </el-popover>
                  <div class="filtrate_input filtrate" slot="reference">
                    <div class="filtrate_single">
                      <div
                        class="filtrate_single_a"
                        v-for="(item, index) in handlearr(copyfiltrateResult)"
                        :key="index"
                      >
                        {{ item.name }}
                      </div>
                      <button
                        slot="reference"
                        v-show="omitDisplay(copyfiltrateResult).length > 0"
                        class="cycle_list_popover"
                        v-popover:refpopover
                      >
                        +{{ omitDisplay(copyfiltrateResult).length }}
                      </button>
                      <img src="@/assets/up.png" alt="" class="cycle_list_up" />
                    </div>
                  </div>
                </div>
                <div v-else @click="openRange" class="device_search_h">
                  <el-input
                    placeholder="请选择"
                    readonly=""
                    class="device_search_t"
                  >
                  </el-input>
                </div>
              </div>
            </el-form-item>
            <div class="groupForm_content2">考勤类型</div>
            <div class="groupForm_type">
              <div
                :class="[
                  'groupForm_type_single',
                  checkedGroup == item.value
                    ? 'groupForm_type_single_select'
                    : '',
                ]"
                v-for="(item, index) in atendanceType"
                @click="handleCheckboxChange3(item.value)"
                :key="index"
              >
                <img
                  v-if="checkedGroup == item.value"
                  src="@/assets/select.png"
                  class="groupForm_type_icon"
                />
                <div class="groupForm_type_single_title">{{ item.name }}</div>
                <div class="groupForm_type_single_tips">
                  {{ item.tips }}
                </div>
                <div class="groupForm_type_single_tips">{{ item.tips2 }}</div>
              </div>
            </div>
            <div v-show="checkedGroup == '1'">
              <div class="groupForm_content">班次设置</div>
              <div v-if="timeData.length == 0" class="groupForm_tip">
                请去新增班次：
                <button class="groupForm_btn" @click="newFormVisible">
                  新建班次
                </button>
              </div>
              <el-table
                :key="fixedKey"
                :data="tableData"
                ref="itemTable"
                @select="itemHandleSelectionChange"
                @select-all="selection_change"
                class="groupForm_t"
              >
                <el-table-column type="selection" width="35"> </el-table-column>
                <el-table-column prop="date" label="工作日" width="80">
                </el-table-column>
                <el-table-column label="班次" width="200">
                  <template slot-scope="scope">
                    <span v-if="scope.row.ischecked">{{
                      scope.row.name +
                      ":" +
                      formatTime(scope.row.arrangeDataList)
                    }}</span>
                    <span v-else>{{ scope.row.relax }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="50">
                  <template slot-scope="scope">
                    <div
                      class="groupForm_btn"
                      @click="changeTime(scope.$index, scope.row)"
                    >
                      更改
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="checkedGroup == '2'" class="class_tips">
              <div class="groupForm_content2">班次设置</div>
              <div class="class_custom">
                自定义设置每人的班次，请在保存考勤组设置后再进行排班
              </div>
            </div>
            <div v-if="checkedGroup == '3'" class="class_tips">
              <div class="groupForm_content2">班次设置</div>
              <div class="free_commuting">
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title3">工作日</div>
                  <el-checkbox-group v-model="freeShiftForm.day">
                    <el-checkbox label="0">周一</el-checkbox>
                    <el-checkbox label="1">周二</el-checkbox>
                    <el-checkbox label="2">周三</el-checkbox>
                    <el-checkbox label="3">周四</el-checkbox>
                    <el-checkbox label="4">周五</el-checkbox>
                    <el-checkbox label="5">周六</el-checkbox>
                    <el-checkbox label="6">周日</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title2">工作时长</div>
                  <el-select
                    v-model="freeShiftForm.workTime"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in workingHours"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="replacement_setting_tips">
                    超过工作时长将计算加班，选择「不限制」将影响加班计算
                  </div>
                </div>
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title2">打卡开始时间</div>
                  <div class="replacement_setting_every">每天</div>
                  <el-time-picker
                    @change="resetBegin"
                    :clearable="false"
                    v-model="freeShiftForm.beginSecond"
                    value-format="HH:mm"
                    format="HH:mm"
                  >
                  </el-time-picker>
                  <div class="replacement_setting_new">开始可以打上班卡</div>
                </div>
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title2">打卡间隔时间</div>
                  <el-select
                    v-model="freeShiftForm.endSecond"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in clockInterval"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="replacement_setting_tips">
                    上班打卡{{ freeShiftForm.endSecond }}分钟后可打下班卡
                  </div>
                </div>
              </div>
            </div>
            <!-- 节假日设置 -->
            <div class="holidays">
              <div class="holidays_title">节假日设置</div>
              <div class="holidays_setting">
                <el-checkbox v-model="useHoliday">节假日不用打卡</el-checkbox>
                <div class="holidays_set" @click="openlHoliday">节假日设置</div>
              </div>
            </div>
            <!-- 最早加班时间限制 -->
            <div class="timelimitation">
              <div class="timelimitation_left">
                <div>每日开始结束时间设置</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="每日的开始时间到结束时间统计为当日考勤（例：开始时间04：00~结束时间次日03：59 即凌晨4点开始到次日凌晨03：59 都统计为今日的考勤）"
                  placement="top"
                >
                  <img
                    src="@/assets/class/tips.png"
                    class="timelimitation_left_icon"
                  />
                </el-tooltip>
                <div class="timelimitation_right">
                  <el-time-picker
                    :disabled="isEdit"
                    v-model="timelimitation.begin"
                    format="HH:mm"
                    value-format="HH:mm"
                    placeholder="请选择"
                  >
                  </el-time-picker>
                  <div class="zhi">至</div>
                  <div>
                    <el-select
                      disabled
                      v-model="timelimitation.isTomorrow"
                      placeholder="当日"
                    >
                      <el-option
                        v-for="item in limitOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-time-picker
                      disabled
                      v-model="timelimitation.end"
                      format="HH:mm"
                      value-format="HH:mm"
                      placeholder="请选择"
                    >
                    </el-time-picker>
                  </div>
                </div>
              </div>
            </div>
            <!-- 加班规则 -->
            <div class="overtime">
              <div class="holidays_title">加班规则</div>
              <div class="overtime_detail">
                <div class="overtime_name">规则详情</div>
                <div class="overtime_set" @click="openOvertimeDialog">设置</div>
              </div>
              <div class="overtime_tips">
                <div class="overtime_settips">
                  <div>工作日</div>
                  <div class="overtime_settips_other">
                    <div v-if="overtimeFormPreserve.weekday.ischecked">
                      <div>
                        {{ dateType(overtimeFormPreserve.weekday.type) }}
                      </div>
                    </div>
                    <div v-else>不允许加班</div>
                  </div>
                </div>
                <div class="overtime_settips">
                  <div>休息日</div>
                  <div class="overtime_settips_other">
                    <div v-if="overtimeFormPreserve.restDay.ischecked">
                      <div>
                        {{ dateType(overtimeFormPreserve.restDay.type) }}
                      </div>
                    </div>
                    <div v-else>不允许加班</div>
                  </div>
                </div>
                <div class="overtime_settips">
                  <div>节假日</div>
                  <div class="overtime_settips_other">
                    <div v-if="overtimeFormPreserve.holidays.ischecked">
                      <div>
                        {{ dateType(overtimeFormPreserve.holidays.type) }}
                      </div>
                    </div>
                    <div v-else>不允许加班</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 补卡申请 -->
            <div class="replacement">
              <div class="holidays_title">补卡申请</div>
              <div class="replacement_checked">
                <el-checkbox v-model="replacementApplication">开启</el-checkbox>
                <div class="replacement_checked_tips">
                  员工异常打卡时可提交申请，审批通过后修改异常
                </div>
              </div>
              <div class="replacement_setting" v-if="replacementApplication">
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title">补卡类型</div>
                  <el-checkbox-group v-model="replacementsForm.type">
                    <el-checkbox label="permitAbsence">旷工</el-checkbox>
                    <el-checkbox label="permitLate">迟到</el-checkbox>
                    <el-checkbox label="permitEarly">早退</el-checkbox>
                    <el-checkbox label="permitOther">其他</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title2">允许补卡时间限制</div>
                  <el-select
                    v-model="replacementsForm.dayLimit"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in timeLimit"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title2">每月允许补卡次数</div>
                  <el-select
                    v-model="replacementsForm.monthMax"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in replacementsNum"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="replacement_setting_top">
                  <div class="replacement_setting_title2">每月补卡截止日期</div>
                  <el-select
                    v-model="replacementsForm.dayMax"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in replacementsDeadline"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="replacement_setting_tips">
                    补卡截止日期当天零点开始，不可对上月异常打卡提交申请
                  </div>
                </div>
                <!-- <div class="replacement_setting_top">
                <div class="replacement_setting_title2">补卡提醒</div>
                <el-select v-model="replacementsForm.tips" placeholder="请选择">
                  <el-option
                    v-for="item in replacementsReminder"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div class="replacement_setting_tips">
                  设置日期若在周末将在周五提前推送
                </div>
              </div> -->
              </div>
            </div>
            <el-form-item style="margin: 40px auto 0 135px">
              <el-button @click="backGroup" class="cancel_button"
                >取消</el-button
              >
              <el-button
                v-if="!isEdit"
                type="primary"
                @click="submitForm"
                class="cancel_button"
                >确认</el-button
              >
              <el-button
                v-else
                type="primary"
                class="cancel_button"
                @click="editGroup"
                >确 认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 选择人员范围 -->
    <el-dialog
      width="900px"
      height="827px"
      :visible.sync="dialogRange"
      custom-class="dialog-range common-style"
      :close-on-click-modal="false"
      @close="cancelRange"
    >
      <template slot="title">
        <div class="title_text">
          请选择人员范围<span>(已有考勤组人员无法选中)</span>
        </div>
      </template>
      <div class="dialog-range-body">
        <div class="dialog-range-left">
          <div class="range_search">
            <el-input
              v-model="search"
              placeholder="输入员工姓名、员工工号"
              @keyup.enter.native="seachResult"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearName"
              v-if="searchRange"
            />
            <img
              src="@/assets/structure/search.png"
              class="searchicon"
              @click="seachResult"
            />
          </div>
          <div class="range-left-all">
            <div class="crumbs" v-if="!isSearchResult">
              <div v-if="crumbsList.length == 1">
                <div
                  v-for="(item, index) in crumbsList"
                  :key="index"
                  @click="breadDept(item, index)"
                >
                  &nbsp;{{ item.name }}
                </div>
              </div>
              <div class="crumbs" v-else>
                <div
                  v-for="(item, index) in crumbsList"
                  :key="index"
                  @click="breadDept(item, index)"
                >
                  &nbsp;{{ item.name }}<span> / </span>
                </div>
              </div>
            </div>
            <div v-else>搜索结果</div>
          </div>
          <div class="distribution_checkbox">
            <div
              class="distribution_checkbox_option"
              v-for="item in deptList"
              :key="item.id"
            >
              <!-- <span v-if="!item.departmentId">&nbsp;{{ item.name }}</span> -->
              <span v-if="!item.userId && !item.jobDepartment"
                ><el-checkbox
                  class="selectItem"
                  v-model="item.ischecked"
                  @change="selectDept2(item)"
                  >{{ item.name }}</el-checkbox
                ></span
              >
              <span v-else
                ><el-checkbox
                  :disabled="!!item.attendanceGroup"
                  class="selectItem"
                  v-model="item.ischecked"
                  @change="selectDept2(item)"
                  >{{ item.jobNumber }} {{ item.name }}</el-checkbox
                ></span
              >
              <el-link
                :disabled="item.ischecked"
                type="primary"
                @click="querySubordinates(item)"
                v-if="!item.userId && !item.jobDepartment"
                class="distribution_checkbox_link"
                >下级</el-link
              >
            </div>
          </div>
        </div>
        <div class="dialog-range-right">
          <div class="selectoption">
            <div class="selectoption_t">
              <div class="selectoption_t_all">
                已选<span :class="[checkList.length > 0 ? 'discolour' : '']"
                  >({{ checkList.length }})</span
                >
              </div>
              <div>
                <el-link type="primary" @click="clearall">清除</el-link>
              </div>
            </div>
            <div class="right-list" v-if="this.checkList.length != 0">
              <div
                class="selectoption_single"
                v-for="(item, index) in checkList"
                :key="index"
              >
                <div>{{ item.jobNumber }} {{ item.name }}</div>
                <img
                  @click="delectDept(item)"
                  src="@/assets/structure/optionclose.png"
                  class="icon-close"
                />
              </div>
            </div>
            <div class="right-list2" v-else>
              <div class="selectoption_none">暂无内容，请在左侧勾选</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer common-style">
        <el-button
          class="add_confirm"
          style="background: none; margin-right: 15px"
          @click="cancelRange"
          >取 消</el-button
        >
        <el-button type="primary" class="add_confirm" @click="searchUserList"
          >确 认</el-button
        >
      </span>
    </el-dialog>
    <!-- 新建班次 -->
    <el-dialog
      :title="tips"
      @close="cancelDiglog"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="70%"
      :key="key"
      class="classdiglog"
    >
      <AddClasses
        :isCheck="false"
        @cancel="cancelDiglog"
        :id="selectClassesId"
        @comfin="comfinDiglog"
      />
    </el-dialog>
    <!-- 更改班次 -->
    <el-dialog
      :visible.sync="dialogChange"
      :key="editClasses"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>更改班次</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="cancelChange"
        />
      </div>
      <div class="diglog_search">
        <div class="diglog_search_input">
          <el-input
            type="text"
            @keydown.enter.native="getClassList"
            v-model="shiftValue"
            placeholder="搜索班次名称"
            class="diglog_search_input_l"
          />
          <img
            src="@/assets/structure/delete.png"
            class="diglog_search_icon"
            @click="clearup"
            v-if="shiftValue"
          />
          <img
            src="@/assets/structure/search.png"
            class="diglog_search_icon"
            @click="getClassList"
          />
        </div>
        <div class="diglog_add" @click="newFormVisible">新建班次</div>
      </div>
      <div class="delectdiglog">
        <el-table
          height="26rem"
          class="elTable"
          :data="shiftList"
          ref="table"
          @selection-change="handleSelectionChange2"
          :header-cell-style="tableCellSytle"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="班次名称" width="200">
          </el-table-column>

          <el-table-column label="考勤时间" width="500">
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                :content="attendanceTime(scope.row.arrangeDataList).join(',')"
                placement="top"
              >
                <div class="time_list">
                  <div
                    class="time_single"
                    v-for="(item, index) in attendanceTime(
                      scope.row.arrangeDataList
                    )"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="editDiglog(scope.row.id)"
                >编辑</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div
          class="newpagination"
          v-if="timePage.total > 0"
          style="padding-left: 20px; box-sizing: border-box"
        >
          <div class="newpagination_text">共 {{ timePage.total }} 条数据</div>
          <el-pagination
            layout="prev, pager, next"
            :total="timePage.total"
            :current-page="timePage.pageNum"
            :page-size="7"
            @current-change="chagePage"
          >
          </el-pagination>
        </div>
      </div>

      <div class="diglog_operation">
        <el-button @click="cancelChange">取消</el-button>
        <el-button type="primary" @click="confirmChange">确认</el-button>
      </div>
    </el-dialog>
    <!-- 节假日设置 -->
    <el-dialog
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="holidaysDialog"
    >
      <div class="diglog_h">
        <div>节假日设置</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="cancelHoliday"
        />
      </div>
      <div class="holidays_heade">
        <div class="holidays_add" @click="openAddHolidays">新增</div>
      </div>
      <div class="delectdiglog">
        <el-table
          height="26rem"
          class="elTable"
          :key="tableKey"
          :data="holidaysList"
          ref="table"
          :header-cell-style="tableCellSytle"
        >
          <el-table-column prop="name" label="节假日名称"> </el-table-column>

          <el-table-column label="开始日期">
            <template slot-scope="scope"
              ><div>
                {{ scope.row.dayBegin }}
              </div></template
            >
          </el-table-column>
          <el-table-column label="结束日期">
            <template slot-scope="scope"
              ><div>
                {{ scope.row.dayEnd }}
              </div></template
            >
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="editHolidays(scope.row)"
                >编辑</el-link
              >
              <el-link
                type="danger"
                style="margin-left: 24px"
                @click="deleteHolidays(scope.row.id)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 新增节假日 -->
    <el-dialog
      width="50%"
      :key="addholidaysKey"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="addholidaysDialog"
    >
      <div class="diglog_h">
        <div>{{ holidaysForm.id ? "编辑节假日" : "新增节假日" }}</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="closeAddHolidays"
        />
      </div>
      <div class="addholidays">
        <el-form
          label-position="top"
          :model="holidaysForm"
          :rules="rules3"
          ref="addholidays"
        >
          <el-form-item label="节假日名称" prop="name">
            <el-input
              v-model="holidaysForm.name"
              placeholder="请填写节假日名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择日期" prop="dateRange">
            <el-date-picker
              v-model="holidaysForm.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="diglog_operation">
          <el-button @click="closeAddHolidays">取消</el-button>
          <el-button type="primary" @click="confirmAddHolidays">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 加班规则 -->
    <el-dialog
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="overtimeDialog"
    >
      <div class="diglog_h">
        <div>加班规则</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="closeOvertimeDialog"
        />
      </div>
      <div class="overtime_frame">
        <div class="overtime_tab">
          <div
            @click="switchOvertimeDay(item.value)"
            :class="[
              'overtime_tab_single',
              overtimeTab == item.value ? 'overtime_tab_single_select' : '',
            ]"
            v-for="item in overtimeTabList"
            :key="item.value"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 工作日 -->
        <div v-show="overtimeTab == 1">
          <div class="overtime_check">
            <el-checkbox v-model="overtimeForm.weekday.ischecked"
              >允许加班</el-checkbox
            >
          </div>
          <div v-if="overtimeForm.weekday.ischecked">
            <div class="groupForm_content2">计算方式</div>
            <div class="overtime_type">
              <div
                v-for="item in overtimeTypeCopy"
                :key="item.value"
                @click="switchOvertimeType(item.value, 'weekdayType')"
                :class="[
                  'overtime_type_sinlge',
                  overtimeForm.weekday.type == item.value
                    ? 'overtime_type_select_back'
                    : '',
                ]"
              >
                <img
                  src="@/assets/select.png"
                  v-if="overtimeForm.weekday.type == item.value"
                  class="overtime_type_select"
                />
                <div class="overtime_type_name">{{ item.name }}</div>
                <div class="overtime_type_tips">{{ item.tips }}</div>
              </div>
            </div>
          </div>
          <div v-if="overtimeForm.weekday.ischecked">
            <div
              class="weekday_num_tips"
              v-if="
                overtimeForm.weekday.type == 2 &&
                checkedGroup == '3' &&
                freeShiftForm.workTime == 0
              "
            >
              「按打卡时长计算」需设置「工作时长」才可算出加班时长
              <span @click="confirmRules">前往设置</span>
            </div>
            <div class="overtime_rule_titime">计算规则</div>
            <div class="overtime_rule">
              <!-- 工作日-按时长打卡 -->
              <div class="weekday" v-show="overtimeForm.weekday.type == 2">
                <div class="weekday_single" v-if="checkedGroup != 3">
                  <div>下班后</div>
                  <el-input
                    oninput="if (value) {
                      value = value.replace(/[^\d]/g, 1);
                      value = value.replace(/^[0]+/,'');
                      if (Number(value) <= 0) {
                        value = 0;
                      }
                      if (Number(value) > 1440) {
                        value = 1440;
                      }
                    }else{
                      value = 0
                    }"
                    v-model="overtimeForm.weekday.beginTime"
                    placeholder=""
                  ></el-input>
                  <div>分钟后开始计算加班</div>
                </div>
                <div class="weekday_single">
                  <div>最短加班时长</div>
                  <el-input
                    oninput="if (value) {
                      value = value.replace(/[^\d]/g, 1);
                      value = value.replace(/^[0]+/,'');
                      if (Number(value) <= 0) {
                        value = 0;
                      }
                      if (Number(value) > 1440) {
                        value = 1440;
                      }
                    }else{
                      value = 0
                    }"
                    v-model="overtimeForm.weekday.minTime"
                    placeholder=""
                  ></el-input>
                  <div>分钟</div>
                  <div class="weekday_tips">
                    不足{{ overtimeForm.weekday.minTime }}分钟记录为未加班
                  </div>
                </div>
                <div>
                  <div class="weekday_single2">
                    <div>最长加班时长</div>
                    <el-input
                      oninput="if (value) {
                        value = value.replace(/[^\d]/g, 1);
                        value = value.replace(/^[0]+/,'');
                        if (Number(value) <= 0) {
                          value = 0;
                        }
                        if (Number(value) > 1440) {
                          value = 1440;
                        }
                      }else{
                        value = 0
                      }"
                      v-model="overtimeForm.weekday.maxTime"
                      placeholder=""
                    ></el-input>
                    <div>分钟</div>
                    <div class="weekday_tips">
                      超过则记录为{{ overtimeForm.weekday.maxTime }}分钟
                    </div>
                  </div>
                  <div v-if="overtimeForm.weekday.error" class="rest_error">
                    {{ overtimeForm.weekday.error }}
                  </div>
                </div>
              </div>
              <div>
                <el-checkbox v-model="overtimeForm.weekday.time"
                  >允许扣除休息时间</el-checkbox
                >
              </div>
              <div v-if="overtimeForm.weekday.time" class="bt">
                <!-- <el-radio-group
                  v-model="overtimeDeductionType"
                  class="overtime_rule_type"
                >
                  <el-radio :label="1">按休息时段扣除</el-radio>
                  <el-radio :label="2">按加班时长扣除</el-radio>
                </el-radio-group> -->
                <div>
                  <div
                    v-for="(item, index) in overtimeForm.weekday.restlist"
                    :key="index"
                  >
                    <div class="rest_period">
                      <div class="rest_period_title">休息时段</div>
                      <div class="rest_period_select">
                        <div class="rest_period_select_date">
                          <el-select
                            v-model="item.isbeginTomorrow"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in datePick"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="rest_period_select_time">
                          <el-time-picker
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="item.beginDaySecond"
                            :clearable="false"
                            placeholder=""
                          >
                          </el-time-picker>
                        </div>
                      </div>
                      <div class="rest_period_heng">-</div>
                      <div class="rest_period_select">
                        <div class="rest_period_select_date">
                          <el-select
                            v-model="item.isendTomorrow"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in datePick"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="rest_period_select_time">
                          <el-time-picker
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="item.endDaySecond"
                            :clearable="false"
                            placeholder=""
                          >
                          </el-time-picker>
                        </div>
                      </div>
                      <img
                        v-if="overtimeForm.weekday.restlist.length < 10"
                        src="@/assets/add.png"
                        class="overtime_rule_type_add"
                        @click="restAdd(overtimeForm.weekday.restlist)"
                      />
                      <img
                        v-if="overtimeForm.weekday.restlist.length > 1"
                        src="@/assets/de.png"
                        class="overtime_rule_type_add"
                        @click="
                          restDelete(overtimeForm.weekday.restlist, index)
                        "
                      />
                    </div>
                    <div v-if="item.error" class="rest_error">
                      {{ item.error }}
                    </div>
                  </div>
                </div>
                <!-- <div v-if="overtimeDeductionType == 2">
                  <div
                    v-for="(item, index) in overtimeDeductionTimeList"
                    :key="index"
                  >
                    <div class="overtime_rule_type2">
                      <div>每加满</div>
                      <el-select
                        :class="[item.tips ? 'error' : '']"
                        v-model="item.topup"
                        placeholder=""
                        @change="changeTopup"
                      >
                        <el-option
                          v-for="citem in item.overtimeDeductionTopup"
                          :key="citem.value"
                          :label="citem.label"
                          :value="citem.value"
                        >
                        </el-option>
                      </el-select>
                      <div>分钟，扣除</div>
                      <el-select
                        :class="[item.tips2 ? 'error' : '']"
                        v-model="item.deduction"
                        placeholder=""
                        @change="changeDeduction($event, index)"
                      >
                        <el-option
                          v-for="citem in overtimeDeductionList"
                          :key="citem.value"
                          :label="citem.label"
                          :value="citem.value"
                        >
                        </el-option>
                      </el-select>
                      <div>分钟</div>
                      <img
                        v-if="overtimeDeductionTimeList.length < 5"
                        src="@/assets/add.png"
                        class="overtime_rule_type_add"
                        @click="addovertimeDeductionTimeList"
                      />
                      <img
                        v-if="overtimeDeductionTimeList.length > 1"
                        src="@/assets/de.png"
                        class="overtime_rule_type_add"
                        @click="deleteovertimeDeductionTimeList(index)"
                      />
                    </div>
                    <div v-if="item.tips" class="error_tips">
                      {{ item.tips }}
                    </div>
                    <div v-if="item.tips2" class="error_tips">
                      {{ item.tips2 }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 休息日 -->
        <div v-show="overtimeTab == 2">
          <div class="overtime_check">
            <el-checkbox v-model="overtimeForm.restDay.ischecked"
              >允许加班</el-checkbox
            >
          </div>
          <div v-if="overtimeForm.restDay.ischecked">
            <div class="groupForm_content2">计算方式</div>
            <div class="overtime_type">
              <div
                v-for="item in overtimeTypeCopy"
                :key="item.value"
                @click="switchOvertimeType(item.value, 'restDayType')"
                :class="[
                  'overtime_type_sinlge',
                  overtimeForm.restDay.type == item.value
                    ? 'overtime_type_select_back'
                    : '',
                ]"
              >
                <img
                  src="@/assets/select.png"
                  v-if="overtimeForm.restDay.type == item.value"
                  class="overtime_type_select"
                />
                <div class="overtime_type_name">{{ item.name }}</div>
                <div class="overtime_type_tips">{{ item.tips }}</div>
              </div>
            </div>
          </div>
          <div v-if="overtimeForm.restDay.ischecked">
            <div class="overtime_rule_titime">计算规则</div>
            <div class="overtime_rule">
              <!-- 休息日 || 休息日 -->
              <div class="restday" v-show="overtimeForm.restDay.type == 2">
                <div class="weekday_single">
                  <div>最短加班时长</div>
                  <el-input
                    oninput=" if (value) {
                      value = value.replace(/[^\d]/g, 1);
                      value = value.replace(/^[0]+/,'');
                      if (Number(value) <= 0) {
                        value = 0;
                      }
                      if (Number(value) > 1440) {
                        value = 1440;
                      }
                    }else{
                      value = 0
                    }"
                    v-model="overtimeForm.restDay.minTime"
                    placeholder=""
                  ></el-input>
                  <div>分钟</div>
                  <div class="weekday_tips">
                    不足{{ overtimeForm.restDay.minTime }}分钟记录为未加班
                  </div>
                </div>
                <div>
                  <div class="weekday_single2">
                    <div>最长加班时长</div>
                    <el-input
                      oninput=" if (value) {
                      value = value.replace(/[^\d]/g, 1);
                      value = value.replace(/^[0]+/,'');
                      if (Number(value) <= 0) {
                        value = 0;
                      }
                      if (Number(value) > 1440) {
                        value = 1440;
                      }
                    }else{
                      value = 0
                    }"
                      v-model="overtimeForm.restDay.maxTime"
                      placeholder=""
                    ></el-input>
                    <div>分钟</div>
                    <div class="weekday_tips">
                      超过则记录为{{ overtimeForm.restDay.maxTime }}分钟
                    </div>
                  </div>
                  <div v-if="overtimeForm.restDay.error" class="rest_error">
                    {{ overtimeForm.restDay.error }}
                  </div>
                </div>
              </div>
              <div>
                <el-checkbox v-model="overtimeForm.restDay.time"
                  >允许扣除休息时间</el-checkbox
                >
              </div>
              <div v-if="overtimeForm.restDay.time" class="bt">
                <!-- <el-radio-group
                  v-model="overtimeForm.restDayTime"
                  class="overtime_rule_type"
                >
                  <el-radio :label="1">按休息时段扣除</el-radio>
                  <el-radio :label="2">按加班时长扣除</el-radio>
                </el-radio-group> -->
                <div>
                  <div
                    v-for="(item, index) in overtimeForm.restDay.restlist"
                    :key="index"
                  >
                    <div class="rest_period">
                      <div class="rest_period_title">休息时段</div>
                      <div class="rest_period_select">
                        <div class="rest_period_select_date">
                          <el-select
                            v-model="item.isbeginTomorrow"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in datePick"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="rest_period_select_time">
                          <el-time-picker
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="item.beginDaySecond"
                            :clearable="false"
                            placeholder=""
                          >
                          </el-time-picker>
                        </div>
                      </div>
                      <div class="rest_period_heng">-</div>
                      <div class="rest_period_select">
                        <div class="rest_period_select_date">
                          <el-select
                            v-model="item.isendTomorrow"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in datePick"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="rest_period_select_time">
                          <el-time-picker
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="item.endDaySecond"
                            :clearable="false"
                            placeholder=""
                          >
                          </el-time-picker>
                        </div>
                      </div>
                      <img
                        v-if="overtimeForm.restDay.restlist.length < 10"
                        src="@/assets/add.png"
                        class="overtime_rule_type_add"
                        @click="restAdd(overtimeForm.restDay.restlist)"
                      />
                      <img
                        v-if="overtimeForm.restDay.restlist.length > 1"
                        src="@/assets/de.png"
                        class="overtime_rule_type_add"
                        @click="
                          restDelete(overtimeForm.restDay.restlist, index)
                        "
                      />
                    </div>
                    <div v-if="item.error" class="rest_error">
                      {{ item.error }}
                    </div>
                  </div>
                </div>
                <!-- <div v-if="overtimeDeductionType == 2">
                  <div
                    v-for="(item, index) in overtimeDeductionTimeList"
                    :key="index"
                  >
                    <div class="overtime_rule_type2">
                      <div>每加满</div>
                      <el-select
                        :class="[item.tips ? 'error' : '']"
                        v-model="item.topup"
                        placeholder=""
                        @change="changeTopup"
                      >
                        <el-option
                          v-for="citem in item.overtimeDeductionTopup"
                          :key="citem.value"
                          :label="citem.label"
                          :value="citem.value"
                        >
                        </el-option>
                      </el-select>
                      <div>分钟，扣除</div>
                      <el-select
                        :class="[item.tips2 ? 'error' : '']"
                        v-model="item.deduction"
                        placeholder=""
                        @change="changeDeduction($event, index)"
                      >
                        <el-option
                          v-for="citem in overtimeDeductionList"
                          :key="citem.value"
                          :label="citem.label"
                          :value="citem.value"
                        >
                        </el-option>
                      </el-select>
                      <div>分钟</div>
                      <img
                        v-if="overtimeDeductionTimeList.length < 5"
                        src="@/assets/add.png"
                        class="overtime_rule_type_add"
                        @click="addovertimeDeductionTimeList"
                      />
                      <img
                        v-if="overtimeDeductionTimeList.length > 1"
                        src="@/assets/de.png"
                        class="overtime_rule_type_add"
                        @click="deleteovertimeDeductionTimeList(index)"
                      />
                    </div>
                    <div v-if="item.tips" class="error_tips">
                      {{ item.tips }}
                    </div>
                    <div v-if="item.tips2" class="error_tips">
                      {{ item.tips2 }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 节假日 -->
        <div v-show="overtimeTab == 3">
          <div class="overtime_check">
            <el-checkbox v-model="overtimeForm.holidays.ischecked"
              >允许加班</el-checkbox
            >
            <div class="overtime_check_tips">
              已设置的节假日为准记为「节假日加班」
            </div>
          </div>
          <div v-if="overtimeForm.holidays.ischecked">
            <div class="groupForm_content2">计算方式</div>
            <div class="overtime_type">
              <div
                v-for="item in overtimeTypeCopy"
                :key="item.value"
                @click="switchOvertimeType(item.value, 'holidaysType')"
                :class="[
                  'overtime_type_sinlge',
                  overtimeForm.holidays.type == item.value
                    ? 'overtime_type_select_back'
                    : '',
                ]"
              >
                <img
                  src="@/assets/select.png"
                  v-if="overtimeForm.holidays.type == item.value"
                  class="overtime_type_select"
                />
                <div class="overtime_type_name">{{ item.name }}</div>
                <div class="overtime_type_tips">{{ item.tips }}</div>
              </div>
            </div>
          </div>
          <div v-if="overtimeForm.holidays.ischecked">
            <div class="overtime_rule_titime">计算规则</div>
            <div class="overtime_rule">
              <!-- 休息日 || 休息日 -->
              <div class="restday" v-show="overtimeForm.holidays.type == 2">
                <div class="weekday_single">
                  <div>最短加班时长</div>
                  <el-input
                    oninput=" if (value) {
                      value = value.replace(/[^\d]/g, 1);
                      value = value.replace(/^[0]+/,'');
                      if (Number(value) <= 0) {
                        value = 0;
                      }
                      if (Number(value) > 1440) {
                        value = 1440;
                      }
                    }else{
                      value = 0
                    }"
                    v-model="overtimeForm.holidays.minTime"
                    placeholder=""
                  ></el-input>
                  <div>分钟</div>
                  <div class="weekday_tips">
                    不足{{ overtimeForm.holidays.minTime }}分钟记录为未加班
                  </div>
                </div>
                <div>
                  <div class="weekday_single2">
                    <div>最长加班时长</div>
                    <el-input
                      oninput=" if (value) {
                      value = value.replace(/[^\d]/g, 1);
                      value = value.replace(/^[0]+/,'');
                      if (Number(value) <= 0) {
                        value = 0;
                      }
                      if (Number(value) > 1440) {
                        value = 1440;
                      }
                    }else{
                      value = 0
                    }"
                      v-model="overtimeForm.holidays.maxTime"
                      placeholder=""
                    ></el-input>
                    <div>分钟</div>
                    <div class="weekday_tips">
                      超过则记录为{{ overtimeForm.holidays.maxTime }}分钟
                    </div>
                  </div>
                  <div v-if="overtimeForm.holidays.error" class="rest_error">
                    {{ overtimeForm.holidays.error }}
                  </div>
                </div>
              </div>
              <div>
                <el-checkbox v-model="overtimeForm.holidays.time"
                  >允许扣除休息时间</el-checkbox
                >
              </div>
              <div v-if="overtimeForm.holidays.time" class="bt">
                <!-- <el-radio-group
                  v-model="overtimeDeductionType"
                  class="overtime_rule_type"
                >
                  <el-radio :label="1">按休息时段扣除</el-radio>
                  <el-radio :label="2">按加班时长扣除</el-radio>
                </el-radio-group> -->
                <div>
                  <div
                    v-for="(item, index) in overtimeForm.holidays.restlist"
                    :key="index"
                  >
                    <div class="rest_period">
                      <div class="rest_period_title">休息时段</div>
                      <div class="rest_period_select">
                        <div class="rest_period_select_date">
                          <el-select
                            v-model="item.isbeginTomorrow"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in datePick"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="rest_period_select_time">
                          <el-time-picker
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="item.beginDaySecond"
                            :clearable="false"
                            placeholder=""
                          >
                          </el-time-picker>
                        </div>
                      </div>
                      <div class="rest_period_heng">-</div>
                      <div class="rest_period_select">
                        <div class="rest_period_select_date">
                          <el-select
                            v-model="item.isendTomorrow"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in datePick"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="rest_period_select_time">
                          <el-time-picker
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="item.endDaySecond"
                            :clearable="false"
                            placeholder=""
                          >
                          </el-time-picker>
                        </div>
                      </div>
                      <img
                        v-if="overtimeForm.holidays.restlist.length < 10"
                        src="@/assets/add.png"
                        class="overtime_rule_type_add"
                        @click="restAdd(overtimeForm.holidays.restlist)"
                      />
                      <img
                        v-if="overtimeForm.holidays.restlist.length > 1"
                        src="@/assets/de.png"
                        class="overtime_rule_type_add"
                        @click="
                          restDelete(overtimeForm.holidays.restlist, index)
                        "
                      />
                    </div>
                    <div v-if="item.error" class="rest_error">
                      {{ item.error }}
                    </div>
                  </div>
                </div>
                <!-- <div v-if="overtimeDeductionType == 2">
                  <div
                    v-for="(item, index) in overtimeDeductionTimeList"
                    :key="index"
                  >
                    <div class="overtime_rule_type2">
                      <div>每加满</div>
                      <el-select
                        :class="[item.tips ? 'error' : '']"
                        v-model="item.topup"
                        placeholder=""
                        @change="changeTopup"
                      >
                        <el-option
                          v-for="citem in item.overtimeDeductionTopup"
                          :key="citem.value"
                          :label="citem.label"
                          :value="citem.value"
                        >
                        </el-option>
                      </el-select>
                      <div>分钟，扣除</div>
                      <el-select
                        :class="[item.tips2 ? 'error' : '']"
                        v-model="item.deduction"
                        placeholder=""
                        @change="changeDeduction($event, index)"
                      >
                        <el-option
                          v-for="citem in overtimeDeductionList"
                          :key="citem.value"
                          :label="citem.label"
                          :value="citem.value"
                        >
                        </el-option>
                      </el-select>
                      <div>分钟</div>
                      <img
                        v-if="overtimeDeductionTimeList.length < 5"
                        src="@/assets/add.png"
                        class="overtime_rule_type_add"
                        @click="addovertimeDeductionTimeList"
                      />
                      <img
                        v-if="overtimeDeductionTimeList.length > 1"
                        src="@/assets/de.png"
                        class="overtime_rule_type_add"
                        @click="deleteovertimeDeductionTimeList(index)"
                      />
                    </div>
                    <div v-if="item.tips" class="error_tips">
                      {{ item.tips }}
                    </div>
                    <div v-if="item.tips2" class="error_tips">
                      {{ item.tips2 }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="diglog_operation">
        <el-button @click="closeOvertimeDialog">取消</el-button>
        <el-button type="primary" @click="confirmRules">确认</el-button>
      </div>
    </el-dialog>

    <!-- 弹窗(更改班次) -->
    <Diglog
      @closeDiglog="closeTips"
      @affirmDiglog="confirmChangeDiglog"
      :isShow="tipsDialog"
      :title="'提示'"
      :tips="'班次编辑后，将影响目前所在班次考勤组的考勤，你确定要编辑吗？'"
    />
    <!-- 弹窗(编辑节假日) -->
    <Diglog
      @closeDiglog="cancelChangeHoliday"
      @affirmDiglog="confirmChangeHoliday"
      :isShow="tipsDialog2"
      :title="'提示'"
      :tips="'节假日编辑后，将影响所有班次考勤组，你确定要编辑吗？'"
    />
    <!-- 弹窗(删除节假日) -->
    <Diglog
      @closeDiglog="tipsDialog3 = false"
      @affirmDiglog="confirmCancelHoliday"
      :isShow="tipsDialog3"
      :title="'提示'"
      :tips="'节假日删除后，将影响所有班次考勤组，你确定要删除吗？'"
    />
  </div>
</template>

<script>
import http from "../../utils/http";
import Diglog from "../../assembly/dialog.vue";
import AddClasses from "./classes/addClasses.vue";

export default {
  components: { Diglog, AddClasses },
  data() {
    return {
      groupForm: {
        name: "",
      },
      checkedGroup: "1",
      useHoliday: false, //节假日开关
      fixedKey: Math.random(), //固定上下班key
      editClasses: Math.random(), //更改班次弹窗
      tableData: [
        {
          date: "周一",
          arrangeDataList: [],
          name: "",
          index: 0,
          relax: "休息",
          ischecked: false,
        },
        {
          date: "周二",
          arrangeDataList: [],
          name: "",
          index: 1,
          relax: "休息",
          ischecked: false,
        },
        {
          date: "周三",
          arrangeDataList: [],
          name: "",
          index: 2,
          relax: "休息",
          ischecked: false,
        },
        {
          date: "周四",
          arrangeDataList: [],
          name: "",
          index: 3,
          relax: "休息",
          ischecked: false,
        },
        {
          date: "周五",
          arrangeDataList: [],
          name: "",
          index: 4,
          relax: "休息",
          ischecked: false,
        },
        {
          date: "周六",
          arrangeDataList: [],
          name: "",
          index: 5,
          relax: "休息",
          ischecked: false,
        },
        {
          date: "周日",
          arrangeDataList: [],
          name: "",
          index: 6,
          relax: "休息",
          ischecked: false,
        },
      ],
      options: [],
      multipleSelection: [],
      dialogChange: false, //更改班次弹窗
      timeData: [], //班次列表
      shiftList: [], //分页班次列表
      timePage: {}, //班次分页
      shiftDay: [
        {
          label: "当日",
          value: 1,
        },
        {
          label: "次日",
          value: 2,
        },
      ],
      shiftValue: "", //班次搜索关键词
      selectionList: [], //更改班次列表
      defaultClass: "", //默认班次
      checkedBC: [], //选中的班次
      selectClassesId: null, //班次id
      currentIndex: 0, //当前更改班次行的Index
      currentChecked: true,
      dialogRange: false, //选择人员范围
      isSearchResult: false,
      search: "", //搜索内容
      topleave: [], //部门列表
      crumbsList: [
        {
          name: "全部",
          departmentId: "",
        },
      ], //面包屑
      deptList: [],
      crumblist: [], //标题
      //面包屑
      crumbscopyList: [
        {
          name: "全部",
          departmentId: null,
        },
      ], //深层面包屑
      crumbscopyListcopy: [
        {
          name: "全部",
          departmentId: null,
        },
      ],
      atendanceType: [
        {
          name: "固定上下班",
          tips: "所有人按照相同时间打卡",
          tips2: "适用于办公室坐班,如早九晚五",
          value: "1",
        },
        {
          name: "排班上下班",
          tips: "不同人员按照各自排班打卡",
          tips2: "适用于工厂、门店等,如三班倒",
          value: "2",
        },
        {
          name: "自由上下班",
          tips: "无时间限制,可随时打卡只统计旷工",
          tips2: "适用于销售、计时工种等",
          value: "3",
        },
      ],
      checkList: [], //所选的列表
      checkPersion: [], //所选的人员
      checkDepartment: [], //所选的部门
      tableSelection: [], //导出表格需要
      multipleSelection: [],
      multipleSelectionCopy: [], //多选操作，不做编译
      isFiltrate: false, //是否进行了筛选
      filtrateResult: [], //筛选字段
      copyfiltrateResult: [], //筛选字段
      filtrateNum: 0, //筛选数量
      searchPass: false,
      searchRange: false,
      prangge: {},
      isEdit: false, //是否是编辑
      groupObj: {}, //考勤组
      groupObjId: null, //考勤组id
      oldDepartIDList: [],
      oldUserIDList: [],
      indeterminate: false,
      currentFix: "",
      oldObj: {},
      peoList: null,
      dialogFormVisible: false, //新建班次
      value1: "",
      tips: "新建班次",
      shiftDeductionList: [], //扣除时间选项
      key: null,
      holidaysList: [], //节假日列表
      tableKey: Math.random(),
      holidaysDialog: false, //节假日弹窗
      addholidaysDialog: false, //新增节假日弹窗
      addholidaysKey: Math.random(), //新增节假日key
      holidaysForm: {}, //节假日表单
      timelimitation: {
        begin: "04:00",
        isTomorrow: false,
      }, //加班限制表单
      limitOption: [
        {
          value: false,
          label: "当日",
        },
        {
          value: true,
          label: "次日",
        },
      ],
      rules3: {
        name: [
          { required: true, message: "请填写节假日名称", trigger: "blur" },
        ],
        dateRange: [
          {
            type: "array",
            required: true,
            message: "请选择日期区间",
            fields: {
              // tpye类型试情况而定,所以如果返回的是date就改成date,如果返回的是string就改成string
              0: { type: "date", required: true, message: "请选择开始日期" },
              1: { type: "date", required: true, message: "请选择结束日期" },
            },
          },
        ],
      }, //节假日表单规则
      freeShiftForm: {
        day: ["0", "1", "2", "3", "4"],
        workTime: 0,
        endSecond: 5,
        beginSecond: "6:00",
      }, //自由上下班班次设置
      workingHours: [
        {
          value: 0,
          label: "不限制",
        },
      ], //工作时长
      overtimeDialog: false, //加班规则弹窗
      overtimeTabList: [
        {
          name: "工作日",
          value: 1,
        },
        {
          name: "休息日",
          value: 2,
        },
        {
          name: "节假日",
          value: 3,
        },
      ],
      overtimeTab: 1, //加班规则tab
      datePick: [
        {
          label: "当日",
          value: false,
        },
        {
          label: "次日",
          value: true,
        },
      ],
      overtimeForm: {
        weekday: {
          ischecked: true, //工作日允许打卡
          type: 1, //工作日计算类型
          time: false, //工作日是否允许扣除休息时间
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //工作日扣除时间段列表
        },
        // -------------------------------
        restDay: {
          ischecked: true, //休息日允许打开
          type: 1, //休息日计算类型
          time: false, //休息日是否允许扣除休息时间
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //休息日扣除时间段列表
        },
        // -------------------------------
        holidays: {
          ischecked: true, //节假日允许打开
          type: 1, //节假日计算类型
          time: false, //节假日是否允许扣除休息时间
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //节假日扣除时间段列表
        },
      }, //加班规则提交表单
      overtimeFormPreserve: {
        weekday: {
          ischecked: true, //工作日允许打卡
          type: 1, //工作日计算类型
          time: false, //工作日是否允许扣除休息时间
          minTime: 30,
          maxTime: 240,
          beginTime: 30,
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //工作日扣除时间段列表
        },
        // -------------------------------
        restDay: {
          ischecked: true, //休息日允许打开
          type: 1, //休息日计算类型
          time: false, //休息日是否允许扣除休息时间
          minTime: 30,
          maxTime: 240,
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //休息日扣除时间段列表
        },
        // -------------------------------
        holidays: {
          ischecked: true, //节假日允许打开
          type: 1, //节假日计算类型
          time: false, //节假日是否允许扣除休息时间
          minTime: 30,
          maxTime: 240,
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //节假日扣除时间段列表
        },
      }, //提交前最终保存的加班规则
      overtimeType: [
        {
          name: "按加班审批时长计算",
          tips: "加班审批通过后，按审批时长计算加班时长",
          value: 1,
        },
        {
          name: "按打卡时长计算",
          tips: "根据打卡时间自动计算加班时长",
          value: 2,
        },
        {
          name: "按审批和打卡时长计算",
          tips: "在加班审批时段内，按打卡时间核算加班时长",
          value: 3,
        },
      ], //加班类型
      overtimeTypeCopy: [
        {
          name: "按加班审批时长计算",
          tips: "加班审批通过后，按审批时长计算加班时长",
          value: 1,
        },
        {
          name: "按打卡时长计算",
          tips: "根据打卡时间自动计算加班时长",
          value: 2,
        },
        {
          name: "按审批和打卡时长计算",
          tips: "在加班审批时段内，按打卡时间核算加班时长",
          value: 3,
        },
      ], //加班类型
      overtimeTypeSelected: 1, //所选加班类型
      overtimeDeduction: false, //允许扣除休息时间
      overtimeDeductionType: 1, //扣除休息时间方式
      overtimeDeductionTimeList: [
        {
          topup: 15,
          deduction: 5,
          overtimeDeductionTopup: [], //加满选项
        },
      ], //扣除时长列表
      overtimeDeductionList: [], //扣除时间选项
      clockInterval: [], //打卡间隔时间
      replacementApplication: true, //补卡申请按钮
      replacementsForm: {
        type: ["permitAbsence", "permitLate", "permitEarly", "permitOther"],
        dayLimit: 0,
        monthMax: 0,
        dayMax: 0,
      }, //补卡表单
      timeLimit: [], //时间限制
      replacementsNum: [], //补卡次数
      replacementsDeadline: [], //截止日期
      replacementsReminder: [], //补卡提醒
      tipsDialog: false, //公共弹窗(更改班次)
      tipsDialog2: false, //公共弹窗(编辑节假日)
      tipsDialog3: false, //公共弹窗(删除节假日)
      holidayId: null, //当前节假日id
      isSubmit: true, //是否可以提交
      copyObject: {}, //处理考勤组的数据
    };
  },
  watch: {
    search(newName, oldName) {
      if (newName == "") {
        this.searchRange = false;
        this.isSearchResult = false;

        this.deptList = [];

        // 获取企业名称
        var company = localStorage.getItem("department");
        this.topleave = JSON.parse(company);

        // this.deptList.push({
        //   name: this.topleave[0].departmentName,
        //   departmentId: "",
        //   ischecked: false,
        // });

        this.crumbsList = [
          {
            name: "全部",
            departmentId: null,
          },
        ];
        this.querySubordinates2("");
      } else {
        this.searchRange = true;
      }
    },

    overtimeDeductionType(newName, oldName) {
      if (newName == 1) {
        this.overtimeRestList = [
          {
            start: new Date(0, 0, 0, 9, 0, 0),
            end: new Date(0, 0, 0, 12, 0, 0),
          },
        ];
      } else {
        this.overtimeDeductionTimeList = [
          {
            topup: 15,
            deduction: 5,
            overtimeDeductionTopup: [],
          },
        ];
      }
    },
    // 整体加班规则监听
    overtimeForm: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        for (let key in newV) {
          if (newV[key].type == 2) {
            var num1 = parseInt(newV[key].minTime);
            var num2 = parseInt(newV[key].maxTime);
            if (num2 <= num1) {
              newV[key].error = "最长加班时长不能小于等于最短加班时长";
            } else {
              delete newV[key].error;
            }
          }
        }
      },
      immediate: true,
    },
    // 监听工作日的休息时段
    "overtimeForm.weekday.restlist": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV.length > 1) {
          for (let index = 1; index < newV.length; index++) {
            if (newV[index].beginDaySecond && newV[index].endDaySecond) {
              var lastendTime = newV[index - 1];
              // 如果上一段选了结束时间
              if (lastendTime) {
                // 判断上个时间的变化
                var judgeLast = this.formatDiffent(
                  lastendTime.beginDaySecond,
                  lastendTime.endDaySecond,
                  lastendTime.isbeginTomorrow,
                  lastendTime.isendTomorrow
                );

                if (!judgeLast) {
                  lastendTime.error = "结束时间必须大于开始时间";
                  break;
                } else {
                  delete lastendTime.error;
                }

                var judge = this.formatDiffent(
                  lastendTime.endDaySecond,
                  newV[index].beginDaySecond,
                  lastendTime.isendTomorrow,
                  newV[index].isbeginTomorrow
                );
                if (!judge) {
                  newV[index].error = "开始时间必须大于上一段结束时间";
                  break;
                } else {
                  var judge = this.formatDiffent(
                    newV[index].beginDaySecond,
                    newV[index].endDaySecond,
                    newV[index].isbeginTomorrow,
                    newV[index].isendTomorrow
                  );
                  if (!judge) {
                    newV[index].error = "结束时间必须大于开始时间";
                    break;
                  } else {
                    delete newV[index].error;
                  }
                }
              } else {
                var judge = this.formatDiffent(
                  newV[index].beginDaySecond,
                  newV[index].endDaySecond,
                  newV[index].isbeginTomorrow,
                  newV[index].isendTomorrow
                );

                if (!judge) {
                  newV[index].error = "结束时间必须大于开始时间";
                  break;
                } else {
                  delete newV[index].error;
                }
              }
            }
          }
        } else {
          var startTime = newV[0];
          var endTime = newV[0];
          var judge = this.formatDiffent(
            startTime.beginDaySecond,
            endTime.endDaySecond,
            startTime.isbeginTomorrow,
            endTime.isendTomorrow
          );
          if (!judge) {
            newV[0].error = "结束时间必须大于开始时间";
            return;
          } else {
            delete newV[0].error;
            return;
          }
        }
      },
      immediate: true,
    },
    // 监听休息日的休息时段
    "overtimeForm.restDay.restlist": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV.length > 1) {
          for (let index = 1; index < newV.length; index++) {
            if (newV[index].beginDaySecond && newV[index].endDaySecond) {
              var lastendTime = newV[index - 1];
              // 如果上一段选了结束时间
              if (lastendTime) {
                // 判断上个时间的变化
                var judgeLast = this.formatDiffent(
                  lastendTime.beginDaySecond,
                  lastendTime.endDaySecond,
                  lastendTime.isbeginTomorrow,
                  lastendTime.isendTomorrow
                );

                if (!judgeLast) {
                  lastendTime.error = "结束时间必须大于开始时间";
                  break;
                } else {
                  delete lastendTime.error;
                }

                var judge = this.formatDiffent(
                  lastendTime.endDaySecond,
                  newV[index].beginDaySecond,
                  lastendTime.isendTomorrow,
                  newV[index].isbeginTomorrow
                );
                if (!judge) {
                  newV[index].error = "开始时间必须大于上一段结束时间";
                  break;
                } else {
                  var judge = this.formatDiffent(
                    newV[index].beginDaySecond,
                    newV[index].endDaySecond,
                    newV[index].isbeginTomorrow,
                    newV[index].isendTomorrow
                  );
                  if (!judge) {
                    newV[index].error = "结束时间必须大于开始时间";
                    break;
                  } else {
                    delete newV[index].error;
                  }
                }
              } else {
                var judge = this.formatDiffent(
                  newV[index].beginDaySecond,
                  newV[index].endDaySecond,
                  newV[index].isbeginTomorrow,
                  newV[index].isendTomorrow
                );

                if (!judge) {
                  newV[index].error = "结束时间必须大于开始时间";
                  break;
                } else {
                  delete newV[index].error;
                }
              }
            }
          }
        } else {
          var startTime = newV[0];
          var endTime = newV[0];
          var judge = this.formatDiffent(
            startTime.beginDaySecond,
            endTime.endDaySecond,
            startTime.isbeginTomorrow,
            endTime.isendTomorrow
          );
          if (!judge) {
            newV[0].error = "结束时间必须大于开始时间";
            return;
          } else {
            delete newV[0].error;
            return;
          }
        }
      },
      immediate: true,
    },
    // 监听休息日的休息时段
    "overtimeForm.holidays.restlist": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV.length > 1) {
          for (let index = 1; index < newV.length; index++) {
            if (newV[index].beginDaySecond && newV[index].endDaySecond) {
              var lastendTime = newV[index - 1];
              // 如果上一段选了结束时间
              if (lastendTime) {
                // 判断上个时间的变化
                var judgeLast = this.formatDiffent(
                  lastendTime.beginDaySecond,
                  lastendTime.endDaySecond,
                  lastendTime.isbeginTomorrow,
                  lastendTime.isendTomorrow
                );

                if (!judgeLast) {
                  lastendTime.error = "结束时间必须大于开始时间";
                  break;
                } else {
                  delete lastendTime.error;
                }

                var judge = this.formatDiffent(
                  lastendTime.endDaySecond,
                  newV[index].beginDaySecond,
                  lastendTime.isendTomorrow,
                  newV[index].isbeginTomorrow
                );
                if (!judge) {
                  newV[index].error = "开始时间必须大于上一段结束时间";
                  break;
                } else {
                  var judge = this.formatDiffent(
                    newV[index].beginDaySecond,
                    newV[index].endDaySecond,
                    newV[index].isbeginTomorrow,
                    newV[index].isendTomorrow
                  );
                  if (!judge) {
                    newV[index].error = "结束时间必须大于开始时间";
                    break;
                  } else {
                    delete newV[index].error;
                  }
                }
              } else {
                var judge = this.formatDiffent(
                  newV[index].beginDaySecond,
                  newV[index].endDaySecond,
                  newV[index].isbeginTomorrow,
                  newV[index].isendTomorrow
                );

                if (!judge) {
                  newV[index].error = "结束时间必须大于开始时间";
                  break;
                } else {
                  delete newV[index].error;
                }
              }
            }
          }
        } else {
          var startTime = newV[0];
          var endTime = newV[0];
          var judge = this.formatDiffent(
            startTime.beginDaySecond,
            endTime.endDaySecond,
            startTime.isbeginTomorrow,
            endTime.isendTomorrow
          );
          if (!judge) {
            newV[0].error = "结束时间必须大于开始时间";
            return;
          } else {
            delete newV[0].error;
            return;
          }
        }
      },
      immediate: true,
    },
    // 监听每日开始结束时间
    "timelimitation.begin": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV) {
          if (newV == "00:00") {
            this.timelimitation.isTomorrow = false;
          } else {
            this.timelimitation.isTomorrow = true;
          }
          this.timelimitation.end = this.subtractMinute(newV);
        }
      },
    },
  },
  created() {
    // 获取班次列表
    this.getClassList();
    this.isEdit = this.$route.params.isEdit;

    // 获取节假日列表
    this.getHoliday(1);

    if (this.isEdit) {
      this.groupObj = this.$route.params.groupObj;
    } else {
      this.checkedGroup = "1";
    }

    this.screenList();

    // this.getAdminList({});
    var cache = localStorage.getItem("userinfo");
    this.id = JSON.parse(cache).id;
  },

  methods: {
    // 获取筛选条件(允许补卡时间限制,每月允许补卡次数,每月补卡截止日期,补卡提醒)
    screenList() {
      // 允许补卡时间限制
      this.timeLimit.push({
        value: 0,
        label: "无限制",
      });
      for (let index = 1; index < 181; index++) {
        this.timeLimit.push({
          value: index,
          label: "过去" + index + "天",
        });
      }
      // 每月允许补卡次数;
      this.replacementsNum.push({
        value: 0,
        label: "无限制",
      });
      for (let index = 1; index < 100; index++) {
        this.replacementsNum.push({
          value: index,
          label: index,
        });
      }
      // 每月补卡截止日期
      var today = new Date();
      var nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      var day = nextMonth.getDate();
      this.replacementsDeadline.push({
        value: 0,
        label: "不设置",
      });
      for (let index = 1; index < day + 1; index++) {
        this.replacementsDeadline.push({
          value: index,
          label: "下月" + index + "号",
        });
      }
      // 补卡提醒
      var currntMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      var currentDay = currntMonth.getDate();
      for (let index = 15; index < currentDay + 1; index++) {
        this.replacementsReminder.push({
          value: "本月" + index,
          label: "本月" + index + "号",
        });
      }
      for (let index = 1; index < day + 1; index++) {
        this.replacementsReminder.push({
          value: "次月" + index,
          label: "次月" + index + "号",
        });
      }

      // // 工作时长
      for (let index = 1; index < 24.1; index = index + 0.5) {
        this.workingHours.push({
          value: index,
          label: index + "小时",
        });
      }
      // 打卡间隔时间
      for (let index = 0; index < 1436; index = index + 5) {
        this.clockInterval.push({
          value: index,
          label: index + "分钟",
        });
      }

      // 生成扣除加满时间
      for (let index = 15; index < 361; index = index + 15) {
        this.overtimeDeductionTimeList[0].overtimeDeductionTopup.push({
          value: index,
          label: index,
        });
      }
      // 生成扣除时间
      for (let index = 5; index < 361; index = index + 5) {
        this.overtimeDeductionList.push({
          value: index,
          label: index,
        });
      }
    },
    getEditClass(obj) {
      let _this = this;
      _this.currentFix = obj.fix;
      _this.groupForm.name = obj.name;
      _this.groupForm.admin = obj.adminName;
      _this.groupForm.id = obj.id;
      _this.oldObj = {
        id: obj.id,
        name: obj.name,
        admin: obj.adminName,
        fix: obj.fix,
      };
      if (!obj.fix) {
        _this.checkedGroup = "2";
      } else {
        _this.checkedGroup = "1";
        let arr3 = obj.weekAttendanceArrangeGetVOList;
        // this.multipleSelection = [];

        for (let i = 0; i < 7; i++) {
          if (arr3[i].arrangeDataList) {
            _this.tableData[i].arrangeDataList = arr3[i].arrangeDataList;
            _this.tableData[i].id = arr3[i].id;
            _this.tableData[i].ischecked = true;
            _this.tableData[i].name = arr3[i].name;
            this.$nextTick(function () {
              _this.$refs.itemTable.toggleRowSelection(this.tableData[i], true);
            });
          } else {
            _this.tableData[i].id = _this.timeData[0].id;
            _this.tableData[i].arrangeDataList =
              _this.timeData[0].arrangeDataList;
            _this.tableData[i].name = _this.timeData[0].name;
            this.$nextTick(function () {
              _this.$refs.itemTable.toggleRowSelection(
                this.tableData[i],
                false
              );
            });
            _this.tableData[i].ischecked = false;
          }
        }
      }
      let arr = obj.attendanceGroupDepartmentGetVOList;
      let arr2 = obj.attendanceGroupUserGetVOList;

      if (arr.length > 0) {
        arr.forEach((item) => {
          _this.oldDepartIDList.push(item.department);
          _this.filtrateResult.push({
            id: item.department,
            name: item.departmentName,
          });
          _this.checkDepartment.push({
            departmentId: item.department,
            name: item.departmentName,
            isold: 1,
          });
          _this.checkList.push({
            departmentId: item.department,
            name: item.departmentName,
          });
        });
      }
      if (arr2.length > 0) {
        arr2.forEach((item) => {
          _this.oldUserIDList.push(item.user);
          _this.filtrateResult.push({ id: item.user, name: item.userName });
          _this.checkPersion.push({
            userId: item.user,
            name: item.userName,
            isold: 1,
          });
          _this.checkList.push({ userId: item.user, name: item.userName });
        });
      }
      _this.filtrateResult = JSON.parse(JSON.stringify(_this.filtrateResult));
      _this.copyfiltrateResult = JSON.parse(
        JSON.stringify(_this.filtrateResult)
      );
      _this.isFiltrate = true;
    },
    // ----------------- 考勤组 --------------------
    // 处理考勤组
    handelGroup(newobj) {
      var obj = JSON.stringify(newobj);
      obj = JSON.parse(obj);
      var _this = this;
      // 部门和人
      var depaList = obj.bmsAttendanceGroupDepartmentGetVOList;
      var peopleList = obj.bmsAttendanceGroupUserGetVOList;
      if (depaList.length > 0) {
        depaList.forEach((item) => {
          _this.oldDepartIDList.push(item.department);
          _this.filtrateResult.push({
            id: item.department,
            name: item.umsDepartment.name,
          });
          _this.checkDepartment.push({
            departmentId: item.department,
            name: item.umsDepartment.name,
            isold: 1,
          });
          _this.checkList.push({
            departmentId: item.department,
            name: item.umsDepartment.name,
          });
        });
      }
      if (peopleList.length > 0) {
        peopleList.forEach((item) => {
          _this.oldUserIDList.push(item.user);
          _this.filtrateResult.push({ id: item.user, name: item.umsUser.name });
          _this.checkPersion.push({
            userId: item.user,
            name: item.umsUser.name,
            isold: 1,
          });
          _this.checkList.push({ userId: item.user, name: item.umsUser.name });
        });
      }

      _this.prangge.departmentList = _this.oldDepartIDList;
      _this.prangge.userList = _this.oldUserIDList;

      // console.log(_this.checkList)
      _this.filtrateResult = JSON.parse(JSON.stringify(_this.filtrateResult));
      _this.copyfiltrateResult = JSON.parse(
        JSON.stringify(_this.filtrateResult)
      );
      _this.isFiltrate = true;

      // 处理节假日
      _this.useHoliday = obj.attendanceGroupConfigData.useHoliday;
      // 处理考勤组名称
      _this.groupForm.name = obj.name;
      _this.groupForm.id = obj.id;

      // 处理考勤类型
      // 1.如果固定值排班
      var fixed = obj.bmsAttendanceArrangeGetVOList;
      var attendanceGroupConfigData = obj.attendanceGroupConfigData;
      if (attendanceGroupConfigData.fixWorkData) {
        var fixedArr = [];
        fixed.map((item) => {
          if (item.id) {
            fixedArr.push({
              id: item.id,
            });
          } else {
            fixedArr.push({
              id: -1,
            });
          }
        });
        _this.checkedGroup = "1";
        _this.multipleSelection = [];
        for (let i = 0; i < 7; i++) {
          fixedArr[i].index = i;
          _this.multipleSelection.push(fixedArr[i]);
          if (fixed[i].arrangeDataList) {
            _this.tableData[i].arrangeDataList = fixed[i].arrangeDataList;
            _this.tableData[i].id = fixed[i].id;
            _this.tableData[i].ischecked = true;
            _this.tableData[i].name = fixed[i].name;
            this.$nextTick(function () {
              _this.$refs.itemTable.toggleRowSelection(this.tableData[i], true);
            });
          } else {
            _this.tableData[i].id = _this.timeData[0].id;
            _this.tableData[i].arrangeDataList =
              _this.timeData[0].arrangeDataList;
            _this.tableData[i].name = _this.timeData[0].name;
            this.$nextTick(function () {
              _this.$refs.itemTable.toggleRowSelection(
                this.tableData[i],
                false
              );
            });
            _this.tableData[i].ischecked = false;
          }
        }
      } else if (attendanceGroupConfigData.freeWorkData) {
        // 2.如果是自由上下班
        _this.checkedGroup = "3";
        const indexArray =
          attendanceGroupConfigData.freeWorkData.chooseList.reduce(
            (acc, curr, index) => {
              if (curr) acc.push(index.toString());
              return acc;
            },
            []
          );
        attendanceGroupConfigData.freeWorkData.day = indexArray;
        attendanceGroupConfigData.freeWorkData.endSecond =
          parseInt(attendanceGroupConfigData.freeWorkData.endSecond) / 60;
        if (
          !attendanceGroupConfigData.freeWorkData.workTime ||
          attendanceGroupConfigData.freeWorkData.workTime == 0
        ) {
          attendanceGroupConfigData.freeWorkData.workTime = 0;
        } else {
          attendanceGroupConfigData.freeWorkData.workTime =
            parseInt(attendanceGroupConfigData.freeWorkData.workTime) / 3600;
        }
        attendanceGroupConfigData.freeWorkData.beginSecond =
          _this.secondsToHourMin(
            attendanceGroupConfigData.freeWorkData.beginSecond
          );
        setTimeout(() => {
          _this.freeShiftForm = attendanceGroupConfigData.freeWorkData;
        }, 500);
      } else {
        // 3.如果是排班上下班
        _this.checkedGroup = "2";
      }

      // 处理开始结束打卡
      if (attendanceGroupConfigData.clockData) {
        var clockData = attendanceGroupConfigData.clockData;
        var isTomorrow = false;
        // 判断结束时间是否第二天
        if (clockData.endDaySecond >= 86400) {
          isTomorrow = true;
        }
        var start = this.secondsToHourMin(clockData.beginDaySecond);
        var end = this.secondsToHourMin(clockData.endDaySecond);

        this.timelimitation = {
          isTomorrow: isTomorrow,
          begin: start,
          end: end,
        };
      }

      // 处理加班规则
      var rule = _this.reverseProcessing(
        obj.attendanceGroupConfigData.overtimeConfigData
      );
      _this.overtimeFormPreserve = rule;

      // 补卡申请
      if (obj.attendanceGroupConfigData.applyRecordConfigData) {
        var obj1 = obj.attendanceGroupConfigData.applyRecordConfigData;

        if (!obj1.dayLimit) {
          obj1.dayLimit = 0;
        }
        if (!obj1.monthMax) {
          obj1.monthMax = 0;
        }
        if (!obj1.dayMax) {
          obj1.dayMax = 0;
        }
        // 判断数组
        var arr = [];
        if (obj1.permitAbsence) {
          arr.push("permitAbsence");
          delete obj1.permitAbsence;
        }
        if (obj1.permitEarly) {
          arr.push("permitEarly");
          delete obj1.permitEarly;
        }
        if (obj1.permitLate) {
          arr.push("permitLate");
          delete obj1.permitLate;
        }
        if (obj1.permitOther) {
          arr.push("permitOther");
          delete obj1.permitOther;
        }
        obj1.type = arr;
        this.replacementApplication = true;
        this.replacementsForm = obj1;

        this.copyObject = JSON.stringify(obj.attendanceGroupConfigData);
        this.copyObject = JSON.parse(this.copyObject);
      } else {
        this.replacementApplication = false;
      }
    },
    // 新建考勤组
    submitForm(form) {
      // 如果没有姓名
      if (!this.groupForm.name) {
        this.$message.error("请输入考勤组名称");
        return;
      }

      if (!this.prangge.departmentList && !this.prangge.userList) {
        this.$message.error("请选择考勤人员");
        return;
      }

      // 最后需要上交的考勤类型
      var attendanceGroupConfigData = {};
      // 处理固定上下班逻辑
      if (this.checkedGroup == 1) {
        let fix = null;
        fix = new Array(7).fill(-1);

        this.multipleSelection.forEach((item) => {
          var value = this.tableData.find((citem) => citem.id == item.id);
          if (value) {
            item.arrangeDataList = value.arrangeDataList;
          }
          fix[item.index] = item.id;
        });

        attendanceGroupConfigData.fixWorkData = {
          arrangeList: fix,
        };

        const allNegativeOnes = fix.every((element) => element === -1);
        if (allNegativeOnes) {
          this.$message.error("请至少勾选一个班次");
          return;
        }
      }


      // 处理自由上下班的逻辑
      if (this.checkedGroup == 3) {
        var value = this.handleFreecommuting();
        if (value.workTime == 0) {
          delete value.workTime;
        }
        attendanceGroupConfigData.freeWorkData = value;
        const allNegativeOnes = value.chooseList.every(
          (element) => element === false
        );
        if (allNegativeOnes) {
          this.$message.error("请至少勾选一个工作日");
          return;
        }

        var time = this.timeToSecond2(this.timelimitation.begin);

        if (value.beginSecond < time) {
          this.$message.error("打卡开始时间必须大于每日开始时间");
          return;
        }
      }

      // 处理过后的补卡限制
      var value = {};
      if (this.replacementApplication) {
        value = this.handelReplacement();
        if (!value) {
          this.$message.error("请选择补卡类型");
          return;
        }
        attendanceGroupConfigData.applyRecordConfigData = value;
      }

      // 处理节假日
      attendanceGroupConfigData.useHoliday = this.useHoliday;

      // 处理早晚打卡时间
      var timeObject = JSON.stringify(this.timelimitation);
      timeObject = JSON.parse(timeObject);
      var deposit = {}; //存放对象
      if (!timeObject.begin || !timeObject.end) {
        this.$message.error("请先设置开始结束时间");
        return;
      }

      var beginTime = this.timeToSecond(timeObject.begin);
      var endTime = this.timeToSecond2(timeObject.end, timeObject.isTomorrow);
      if (beginTime > endTime) {
        this.$message.error("结束时间必须大于开始时间");
        return;
      }
      if (endTime - beginTime > 86400) {
        this.$message.error("开始时间到结束时间不能大于24小时");
        return;
      }

      // 如果是固定班次，要判断是否都在这个时间段内
      if (this.checkedGroup == 1) {
        this.multipleSelection.map((item) => {
          item.begin = 0;
          item.end = 0;
          if (item.arrangeDataList) {
            item.begin =
              item.arrangeDataList[0].clockData.beginDaySecond -
              item.arrangeDataList[0].beforeAttendanceTime;
            item.end =
              item.arrangeDataList[item.arrangeDataList.length - 1].clockData
                .endDaySecond +
              item.arrangeDataList[item.arrangeDataList.length - 1]
                .afterAttendanceTime;
          }
        });
        var isDown = this.checkTimeRanges(
          this.multipleSelection,
          beginTime,
          endTime
        );
        if (!isDown) {
          this.$message.error("班次不在每日开始结束范围内,请修改后再保存");
          return;
        }
      }
      deposit = {
        beginDaySecond: beginTime,
        endDaySecond: endTime,
      };

      attendanceGroupConfigData.clockData = deposit;

      // 加班规则处理
      attendanceGroupConfigData.overtimeConfigData = this.handleRule();

      // 工作日
      var work =
        attendanceGroupConfigData.overtimeConfigData.workOvertimeConfigItemData;
      // 休息日
      var rest =
        attendanceGroupConfigData.overtimeConfigData.restOvertimeConfigItemData;
      // 节假日
      var holiday =
        attendanceGroupConfigData.overtimeConfigData
          .holidayOvertimeConfigItemData;
      if (this.checkedGroup == 3) {
        if (
          work.overtimeConfigItemMixTimeData ||
          rest.overtimeConfigItemMixTimeData ||
          holiday.overtimeConfigItemMixTimeData
        ) {
          this.$message.error("加班规则错误,请重新设置");
          return;
        }
      }
      if (!this.isSubmit) {
        return;
      }

      this.isSubmit = false;

      // 最终生成的数据
      let obj1 = {};
      obj1 = {
        ...this.prangge,
        ...this.groupForm,
        ...{
          attendanceGroupConfigData: attendanceGroupConfigData,
        },
      };

      const loading = this.$loading({
        lock: true,
        text: "新建考勤组中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      http.post("/bmsAttendanceGroup/add", obj1).then((res) => {
        this.isSubmit = true;
        loading.close();
        if (res.code == 200) {
          this.$message.success("考勤组新建成功，考勤规则将于明日生效");
          this.$router.replace({ path: "/group" });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //编辑考勤组
    editGroup() {
      var _that = this;
      // 如果没有姓名
      if (!_that.groupForm.name) {
        _that.$message.error("请输入考勤组名称");
        return;
      }

      if (!_that.prangge.departmentList && !_that.prangge.userList) {
        _that.$message.error("请选择考勤人员");
        return;
      }
      // 最后需要上交的考勤类型
      var attendanceGroupConfigData = {};
      // 处理固定上下班逻辑
      if (_that.checkedGroup == 1) {
        let fix = null;
        fix = new Array(7).fill(-1);
        _that.multipleSelection.forEach((item) => {
          var value = _that.tableData.find((citem) => citem.id == item.id);
          if (value) {
            item.arrangeDataList = value.arrangeDataList;
          }
          fix[item.index] = item.id;
        });
        attendanceGroupConfigData.fixWorkData = {
          arrangeList: fix,
        };

        const allNegativeOnes = fix.every((element) => element === -1);
        if (allNegativeOnes) {
          _that.$message.error("请至少勾选一个班次");
          return;
        }
      }

      // 处理自由上下班的逻辑
      if (_that.checkedGroup == 3) {
        var value = _that.handleFreecommuting();
        if (value.workTime == 0) {
          delete value.workTime;
        }
        attendanceGroupConfigData.freeWorkData = value;
        const allNegativeOnes = value.chooseList.every(
          (element) => element === false
        );
        if (allNegativeOnes) {
          _that.$message.error("请至少勾选一个工作日");
          return;
        }

        var time = _that.timeToSecond2(_that.timelimitation.begin);

        if (value.beginSecond < time) {
          _that.$message.error("打卡开始时间必须大于每日开始时间");
          return;
        }
      }
      // 处理过后的补卡限制
      var value = {};
      if (_that.replacementApplication) {
        value = _that.handelReplacement();
        attendanceGroupConfigData.applyRecordConfigData = value;
        if (!value) {
          _that.$message.error("请选择补卡类型");
          return;
        }
      }

      // 判断早晚打卡时间
      var timeObject = JSON.stringify(_that.timelimitation);
      timeObject = JSON.parse(timeObject);
      var deposit = {}; //存放对象
      var beginTime = _that.timeToSecond(timeObject.begin);
      var endTime = _that.timeToSecond2(timeObject.end, timeObject.isTomorrow);
      // 如果是固定班次，要判断是否都在这个时间段内
      if (_that.checkedGroup == 1) {
        _that.multipleSelection.map((item) => {
          item.begin = 0;
          item.end = 0;
          if (item.arrangeDataList) {
            item.begin =
              item.arrangeDataList[0].clockData.beginDaySecond -
              item.arrangeDataList[0].beforeAttendanceTime;
            item.end =
              item.arrangeDataList[item.arrangeDataList.length - 1].clockData
                .endDaySecond +
              item.arrangeDataList[item.arrangeDataList.length - 1]
                .afterAttendanceTime;
          }
        });
        var isDown = _that.checkTimeRanges(
          _that.multipleSelection,
          beginTime,
          endTime
        );

        if (!isDown) {
          _that.$message.error("班次不在每日开始结束范围内,请修改后再保存");
          return;
        }
      }
      deposit = {
        beginDaySecond: beginTime,
        endDaySecond: endTime,
      };

      attendanceGroupConfigData.clockData = deposit;

      // 处理节假日
      attendanceGroupConfigData.useHoliday = _that.useHoliday;

      // 加班规则处理
      attendanceGroupConfigData.overtimeConfigData = _that.handleRule();

      // 工作日
      var work =
        attendanceGroupConfigData.overtimeConfigData.workOvertimeConfigItemData;
      // 休息日
      var rest =
        attendanceGroupConfigData.overtimeConfigData.restOvertimeConfigItemData;
      // 节假日
      var holiday =
        attendanceGroupConfigData.overtimeConfigData
          .holidayOvertimeConfigItemData;
      if (this.checkedGroup == 3) {
        if (
          work.overtimeConfigItemMixTimeData ||
          rest.overtimeConfigItemMixTimeData ||
          holiday.overtimeConfigItemMixTimeData
        ) {
          this.$message.error("加班规则错误,请重新设置");
          return;
        }
      }

      var newPrangge = {};

      // 处理人员和部门
      if (
        JSON.stringify(_that.prangge.departmentList) ===
          JSON.stringify(_that.oldDepartIDList) &&
        JSON.stringify(_that.prangge.userList) ===
          JSON.stringify(_that.oldUserIDList)
      ) {
        // 相同的部门
        console.log("相同的部门和人员，没有任何变动");
      } else {
        if (_that.prangge.departmentList) {
          newPrangge.departmentList = _that.prangge.departmentList;
        }
        if (_that.prangge.userList) {
          newPrangge.userList = _that.prangge.userList;
        }
      }

      if (!_that.isSubmit) {
        return;
      }

      _that.isSubmit = false;

      // 最终生成的数据
      let obj1 = {};

      obj1 = {
        ..._that.groupForm,
        ...newPrangge,
        ...{
          attendanceGroupConfigData: attendanceGroupConfigData,
        },
      };

      const loading = _that.$loading({
        lock: true,
        text: "编辑考勤组中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      http.post("/bmsAttendanceGroup/update", obj1).then((res) => {
        _that.isSubmit = true;
        loading.close();
        if (res.code == 200) {
          _that.$message.success("修改成功,考勤规则将于明日生效");
          _that.$router.replace({ path: "/group" });
        } else {
          if (res.status == 500) {
          } else _that.$message.error(res.message);
        }
      });
    },
    deepEqual(obj1, obj2) {
      // 判断类型是否相同
      if (typeof obj1 !== typeof obj2) {
        return false;
      }

      // 如果是基本类型直接比较值
      if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
        return obj1 === obj2;
      }

      // 判断属性数量是否相同
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      // 递归比较对象的每个属性
      for (let key of keys1) {
        if (!this.deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    },
    equals(a, b) {
      let ArrayA = Object.getOwnPropertyNames(a);
      let ArrayB = Object.getOwnPropertyNames(b);

      if (ArrayA.length != ArrayB.length) return false;
      for (let i = 0; i < ArrayA.length; i++) {
        let propNameA = ArrayA[i];
        let propNameB = ArrayB[i];

        if (!a.hasOwnProperty(propNameB)) return false;

        if (a instanceof Object) {
          if (equals(a[propNameA], b[propNameA])) {
          } else {
            return false;
          }
        } else {
          if (a[propNameA] === b[propNameA]) {
          } else {
            return false;
          }
        }

        return true;
      }
    },
    beforeCheck() {
      var _this = this;
      if (!_this.groupForm.name) {
        this.$message.error("考勤组名称不能为空");
        return false;
      }
      if (!_this.groupForm.admin) {
        this.$message.warning("考勤组负责人不能为空");
        return false;
      }
      if (_this.filtrateResult <= 0) {
        this.$message.error("部门和人员不能为空");
        return false;
      }
      return true;
    },
    getAbsentValues(arr1, arr2) {
      let res = [];
      arr2.forEach((item, idx) => {
        if (arr1.indexOf(item) == -1) res.push(item);
      });
      return res;
    },
    getAdminList(obj) {
      http.get("/user/admin_list", obj).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            this.options.push({ id: item.id, label: item.name });
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    clearClass() {
      var _this = this;
      this.checkedGroup = "1";
      _this.groupForm = {};
      _this.prangge = {};
      _this.isFiltrate = false;
      _this.filtrateResult = [];
      _this.copyfiltrateResult = [];
      for (let i = 0; i < 7; i++) {
        if (i <= 4)
          this.$refs.itemTable.toggleRowSelection(this.tableData[i], true);
        else this.$refs.itemTable.toggleRowSelection(this.tableData[i], false);
      }

      _this.tableData.map((item, index) => {
        item.id = _this.timeData[0].id;
        item.arrangeDataList = _this.timeData[0].arrangeDataList;
        item.name = _this.timeData[0].name;
      });
      _this.tableData.map((item, index) => {
        if (index <= 4) {
          this.multipleSelection.push(item);
          item.ischecked = true;
        } else {
          item.ischecked = false;
        }
      });
    },
    // 处理自由上下班的设置
    handleFreecommuting() {
      var object = JSON.stringify(this.freeShiftForm);
      object = JSON.parse(object);

      // 小时转换成秒
      object.endSecond = object.endSecond * 60;
      object.workTime = object.workTime * 3600;
      // 将时间换成秒
      var timeArray = object.beginSecond.split(":");
      var hours = parseInt(timeArray[0]);
      var minutes = parseInt(timeArray[1]);

      // 将小时和分钟转换为秒数并相加
      var totalSeconds = hours * 3600 + minutes * 60;
      object.beginSecond = totalSeconds;

      var arr = [false, false, false, false, false, false, false];
      // 遍历数组a，将对应位置的arr值设为true
      object.day.forEach(function (index) {
        arr[index] = true;
      });
      delete object.day;
      object.chooseList = arr;

      return object;
    },
    // 反向处理加班规则
    reverseProcessing(obj) {
      var newObj = {};
      var weekday = obj.workOvertimeConfigItemData;
      var rest = obj.restOvertimeConfigItemData;
      var holiday = obj.holidayOvertimeConfigItemData;
      if (this.checkedGroup == 3) {
        let newArray = this.overtimeType.slice(0, -1);
        this.overtimeTypeCopy = newArray;
      } else {
        this.overtimeTypeCopy = this.overtimeType;
      }

      // 工作日配置
      if (Object.keys(weekday).length !== 0) {
        var type = null;
        var time = false;
        var otherObj = {};
        var arr = [
          {
            isbeginTomorrow: false,
            isendTomorrow: false,
            beginDaySecond: "9:00",
            endDaySecond: "12:00",
          },
        ];
        // 判断是在哪个类型
        if (weekday.overtimeConfigItemApplyTimeData) {
          var clockDataList =
            weekday.overtimeConfigItemApplyTimeData.clockDataList;
          type = 1;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        } else if (weekday.overtimeConfigItemClockTimeData) {
          var clockDataList =
            weekday.overtimeConfigItemClockTimeData.clockDataList;
          type = 2;
          otherObj.beginTime =
            weekday.overtimeConfigItemClockTimeData.beginTime / 60;
          otherObj.maxTime =
            weekday.overtimeConfigItemClockTimeData.maxTime / 60;
          otherObj.minTime =
            weekday.overtimeConfigItemClockTimeData.minTime / 60;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        } else {
          var clockDataList =
            weekday.overtimeConfigItemMixTimeData.clockDataList;
          type = 3;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        }
        var obj = {
          ischecked: true,
          type: type,
          time: time,
          restlist: arr,
        };

        if (!otherObj.minTime) {
          otherObj.minTime = 30;
        }
        if (!otherObj.maxTime) {
          otherObj.maxTime = 240;
        }
        if (!otherObj.beginTime) {
          otherObj.beginTime = 30;
        }

        newObj.weekday = Object.assign(obj, otherObj);
      } else {
        var obj = {
          ischecked: false,
          type: 1, //工作日计算类型
          time: false,
          minTime: 30,
          maxTime: 240,
          beginTime: 30,
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ],
        };
        newObj.weekday = obj;
      }

      // 休息日配置
      if (Object.keys(rest).length !== 0) {
        var type = null;
        var time = false;
        var otherObj = {};
        var arr = [
          {
            isbeginTomorrow: false,
            isendTomorrow: false,
            beginDaySecond: "9:00",
            endDaySecond: "12:00",
          },
        ];
        // 判断是在哪个类型
        if (rest.overtimeConfigItemApplyTimeData) {
          var clockDataList =
            rest.overtimeConfigItemApplyTimeData.clockDataList;
          type = 1;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        } else if (rest.overtimeConfigItemClockTimeData) {
          var clockDataList =
            rest.overtimeConfigItemClockTimeData.clockDataList;

          type = 2;
          otherObj.maxTime = rest.overtimeConfigItemClockTimeData.maxTime / 60;
          otherObj.minTime = rest.overtimeConfigItemClockTimeData.minTime / 60;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        } else {
          var clockDataList = rest.overtimeConfigItemMixTimeData.clockDataList;
          type = 3;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        }
        var obj = {
          ischecked: true,
          type: type,
          time: time,
          restlist: arr,
        };

        if (!otherObj.minTime) {
          otherObj.minTime = 30;
        }
        if (!otherObj.maxTime) {
          otherObj.maxTime = 240;
        }

        newObj.restDay = Object.assign(obj, otherObj);
      } else {
        var obj = {
          ischecked: false,
          type: 1, //休息日计算类型
          time: false, //休息日是否允许扣除休息时间
          minTime: 30,
          maxTime: 240,
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ], //休息日扣除时间段列表
        };
        newObj.restDay = obj;
      }

      // 节假日配置
      if (Object.keys(holiday).length !== 0) {
        var type = null;
        var time = false;
        var otherObj = {};
        var arr = [
          {
            isbeginTomorrow: false,
            isendTomorrow: false,
            beginDaySecond: "9:00",
            endDaySecond: "12:00",
          },
        ];
        // 判断是在哪个类型
        if (holiday.overtimeConfigItemApplyTimeData) {
          var clockDataList =
            holiday.overtimeConfigItemApplyTimeData.clockDataList;
          type = 1;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        } else if (holiday.overtimeConfigItemClockTimeData) {
          var clockDataList =
            holiday.overtimeConfigItemClockTimeData.clockDataList;
          type = 2;
          otherObj.maxTime =
            holiday.overtimeConfigItemClockTimeData.maxTime / 60;
          otherObj.minTime =
            holiday.overtimeConfigItemClockTimeData.minTime / 60;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        } else {
          var clockDataList =
            holiday.overtimeConfigItemMixTimeData.clockDataList;
          type = 3;
          if (clockDataList.length > 0) {
            time = true;
            arr = [];
            clockDataList.map((item) => {
              var isBegin = false;
              var isEnd = false;
              if (item.beginDaySecond >= 86400) {
                item.beginDaySecond = item.beginDaySecond - 86400;
                isBegin = true;
              }
              if (item.endDaySecond >= 86400) {
                item.endDaySecond = item.endDaySecond - 86400;
                isEnd = true;
              }
              arr.push({
                isbeginTomorrow: isBegin,
                isendTomorrow: isEnd,
                beginDaySecond: this.secondsToHourMin(item.beginDaySecond),
                endDaySecond: this.secondsToHourMin(item.endDaySecond),
              });
            });
          }
        }
        var obj = {
          ischecked: true,
          type: type,
          time: time,
          restlist: arr,
        };

        if (!otherObj.minTime) {
          otherObj.minTime = 30;
        }
        if (!otherObj.maxTime) {
          otherObj.maxTime = 240;
        }

        newObj.holidays = Object.assign(obj, otherObj);
      } else {
        var obj = {
          ischecked: false,
          type: 1, //节假日计算类型
          time: false, //节假日是否允许扣除休息时间
          minTime: 30,
          maxTime: 240,
          restlist: [
            {
              isbeginTomorrow: false,
              isendTomorrow: false,
              beginDaySecond: "9:00",
              endDaySecond: "12:00",
            },
          ],
        };
        newObj.holidays = obj;
      }

      return newObj;
    },
    // 判断固定排班是不是都在可允许的范围内
    checkTimeRanges(arr, start, dend) {
      let filteredItems = arr.filter((item) => item.id !== -1);
      // 遍历数组中的每个对象
      for (let i = 0; i < filteredItems.length; i++) {
        const { begin, end } = filteredItems[i];

        // 检查begin和end是否都在start和end之间
        if (begin < start || end > dend) {
          // 如果任何一个对象的begin或end不满足条件，则返回false
          return false;
        }
      }

      // 如果所有对象都满足条件，则返回true
      return true;
    },
    // ----------------- 班次 --------------------
    // 获取班次列表
    getClassList() {
      var object = {};
      if (this.shiftValue) {
        object = {
          name: this.shiftValue,
        };
      }
      var _this = this;
      http
        .get(
          "/bmsAttendanceArrange/list",
          Object.assign(
            {
              pageNum: 1,
              pageSize: 7,
            },
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            _this.timeData = res.data.list;
            _this.shiftList = res.data.list;
            _this.timePage = res.data;
            if (this.isEdit) {
              this.handelGroup(this.groupObj);
              if (this.checkedGroup != "1") {
                if (_this.timeData.length > 0) {
                  _this.tableData.map((item, index) => {
                    item.id = _this.timeData[0].id;
                    item.arrangeDataList = _this.timeData[0].arrangeDataList;
                    item.name = _this.timeData[0].name;
                  });
                  _this.tableData.map((item, index) => {
                    if (index <= 4) {
                      _this.multipleSelection.push(item);
                      _this.$nextTick(() => {
                        _this.$set(item, "ischecked", true);
                      });
                    }
                  });
                  for (let i = 0; i < 5; i++) {
                    this.$refs.itemTable.toggleRowSelection(
                      this.tableData[i],
                      true
                    );
                  }
                }
              }
            } else {
              if (_this.timeData.length > 0) {
                _this.tableData.map((item, index) => {
                  item.id = _this.timeData[0].id;
                  item.arrangeDataList = _this.timeData[0].arrangeDataList;
                  item.name = _this.timeData[0].name;
                });
                _this.tableData.map((item, index) => {
                  if (index <= 4) {
                    _this.multipleSelection.push(item);
                    _this.$nextTick(() => {
                      _this.$set(item, "ischecked", true);
                    });
                  }
                });
                for (let i = 0; i < 5; i++) {
                  this.$refs.itemTable.toggleRowSelection(
                    this.tableData[i],
                    true
                  );
                }
              }
            }
            this.querySubordinates2("");
          } else {
            _this.$message.error(res.message);
          }
        });
    },
    // 获取班次列表(更改班次)
    editClassListCopy() {
      var object = {};
      if (this.shiftValue) {
        object = {
          name: this.shiftValue,
        };
      }
      var _this = this;
      http
        .get(
          "/bmsAttendanceArrange/list",
          Object.assign(
            {
              pageNum: 1,
              pageSize: 7,
            },
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            _this.timeData = res.data.list;
            _this.shiftList = res.data.list;
            _this.timePage = res.data;
            var obj = JSON.stringify(this.groupObj);
            obj = JSON.parse(obj);
            var fixed = obj.bmsAttendanceArrangeGetVOList;
            if (this.checkedGroup == "1") {
              for (let i = 0; i < 7; i++) {
                if (_this.tableData[i].ischecked) {
                  this.$nextTick(function () {
                    _this.$refs.itemTable.toggleRowSelection(
                      this.tableData[i],
                      true
                    );
                  });
                } else {
                  this.$nextTick(function () {
                    _this.$refs.itemTable.toggleRowSelection(
                      this.tableData[i],
                      false
                    );
                  });
                }
              }
            }
          } else {
            _this.$message.error(res.message);
          }
        });
    },
    // 清除输入框
    clearup() {
      this.shiftValue = "";
      this.getClassList();
    },
    // 考勤时间
    attendanceTime(arr) {
      var newArr = [];
      if (arr) {
        arr.map((item) => {
          var start = ""; //开始时间
          var end = ""; //结束时间
          if (
            item.clockData.beginDaySecond ||
            item.clockData.beginDaySecond == 0
          ) {
            start = this.secondsToHourMin(item.clockData.beginDaySecond);
          }
          if (item.clockData.endDaySecond) {
            end = this.secondsToHourMin(item.clockData.endDaySecond);
          }

          newArr.push(start + "-" + end);
        });

        return newArr;
      } else {
        return newArr;
      }
    },
    // 编辑班次
    editDiglog(id) {
      this.key = Math.random();
      this.selectClassesId = id;

      this.tips = "编辑班次";
      this.tipsDialog = true;
    },
    // 班次列表分页
    chagePage(num) {
      var _this = this;
      var object = {};
      if (_this.shiftValue) {
        object = {
          name: _this.shiftValue,
        };
      }
      http
        .get(
          "/bmsAttendanceArrange/list",
          Object.assign(
            {
              pageNum: num,
              pageSize: 7,
            },
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            _this.shiftList = res.data.list;
            console.log(this.checkedBC[0]);
            if (this.checkedBC[0]) {
              if (this.checkedBC[0].name) {
                var value = _this.shiftList.find(
                  (item) => item.name == this.checkedBC[0].name
                );
                if (value) {
                  _this.$nextTick(() => {
                    _this.$refs.table.clearSelection();
                    _this.$refs.table.toggleRowSelection(value);
                  });
                }
              }
            }
          } else {
            _this.$message.error(res.message);
          }
        });
    },
    // 新建班次弹窗
    newFormVisible() {
      this.key = Math.random();
      setTimeout(() => {
        this.dialogFormVisible = true;
      }, 200);
    },
    // 取消弹窗(班次)
    cancelDiglog() {
      this.dialogFormVisible = false;
      this.selectClassesId = null;
      this.tips = "新建班次";
    },
    // 确认选择
    comfinDiglog(value) {
      var confimForm = {}; //最终提交的表单
      // 深拷贝
      confimForm = JSON.stringify(value);
      confimForm = JSON.parse(confimForm);

      var shiftList = confimForm.arrangeDataList; //提交的班次列表
      var ruleList = confimForm.clockDataList; //休息时间列表
      // 要检查的键
      let keysToCheck = [
        "errortips",
        "errortips2",
        "errorignore",
        "errorAbsenteeism",
        "errorEearlyAbsenteeism",
        "errorEearly",
      ];
      let containsAtLeastOneKey = shiftList.some((obj) => {
        return keysToCheck.some((key) => obj.hasOwnProperty(key));
      });
      // 如果存在错误
      if (containsAtLeastOneKey) {
        return;
      }
      // 要检查的键(休息时间)
      let keysToCheck2 = ["error"];
      let containsAtLeastOneKey2 = ruleList.some((obj) => {
        return keysToCheck2.some((key) => obj.hasOwnProperty(key));
      });
      // 如果存在错误(休息时间)
      if (containsAtLeastOneKey2) {
        return;
      }

      shiftList.map((item) => [(item.clockDataList = [])]);

      ruleList.map((item) => {
        if (item.beginTimeData.daySecond) {
          item.beginTimeData.daySecond = this.timeToSecond(
            item.beginTimeData.daySecond
          );
          if (item.beginTimeData.tomorrow) {
            item.beginTimeData.daySecond = item.beginTimeData.daySecond + 86400;
          }
        }

        if (item.endTimeData.daySecond) {
          item.endTimeData.daySecond = this.timeToSecond(
            item.endTimeData.daySecond
          );
          if (item.endTimeData.tomorrow) {
            item.endTimeData.daySecond = item.endTimeData.daySecond + 86400;
          }
        }

        shiftList[item.indexPeriod].clockDataList.push({
          beginDaySecond: item.beginTimeData.daySecond,
          endDaySecond: item.endTimeData.daySecond,
        });
      });

      shiftList.map((item) => {
        // 要删除的属性列表(上班)
        let propertiesToRemove = [
          "beforeAttendanceTime",
          "beginAbsenceTime",
          "beginIgnoreTime",
          "beginChoose",
          "lateTime",
        ];
        // 要删除的属性列表(下班)
        let propertiesToRemove2 = [
          "endChoose",
          "endIgnoreTime",
          "afterAttendanceTime",
        ];
        // 判断需不需要打卡(上班卡)
        if (!item.beginTimeChoose) {
          propertiesToRemove.forEach((prop) => {
            if (item.hasOwnProperty(prop)) {
              delete item[prop];
            }
          });
        }
        // 判断需不需要打卡(下班卡)
        if (!item.endTimeChoose) {
          propertiesToRemove2.forEach((prop) => {
            if (item.hasOwnProperty(prop)) {
              delete item[prop];
            }
          });
        }
        // 转化
        if (item.afterAttendanceTime) {
          item.afterAttendanceTime = parseInt(item.afterAttendanceTime) * 60;
        }
        if (item.beforeAttendanceTime) {
          item.beforeAttendanceTime = parseInt(item.beforeAttendanceTime) * 60;
        }
        if (item.beginAbsenceTime) {
          item.beginAbsenceTime = parseInt(item.beginAbsenceTime) * 60;
        }
        if (item.beginNoneTime) {
          item.beginNoneTime = parseInt(item.beginNoneTime) * 60;
        }
        if (item.endNoneTime) {
          item.endNoneTime = parseInt(item.endNoneTime) * 60;
        }
        if (item.beginIgnoreTime) {
          item.beginIgnoreTime = parseInt(item.beginIgnoreTime) * 60;
        }
        if (item.endIgnoreTime) {
          item.endIgnoreTime = parseInt(item.endIgnoreTime) * 60;
        }
        if (item.lateTime) {
          item.lateTime = parseInt(item.lateTime) * 60;
        }
        if (item.earlyTime) {
          item.earlyTime = parseInt(item.earlyTime) * 60;
        }
        if (item.earlyAbsenceTime) {
          item.earlyAbsenceTime = parseInt(item.earlyAbsenceTime) * 60;
        }

        if (item.clockData.beginTimeData.daySecond) {
          item.clockData.beginDaySecond = this.timeToSecond(
            item.clockData.beginTimeData.daySecond
          );
          if (item.clockData.beginTimeData.tomorrow) {
            item.clockData.beginDaySecond =
              item.clockData.beginDaySecond + 86400;
          }

          delete item.clockData.beginTimeData;
        }

        if (item.clockData.endTimeData.daySecond) {
          item.clockData.endDaySecond = this.timeToSecond(
            item.clockData.endTimeData.daySecond
          );
          if (item.clockData.endTimeData.tomorrow) {
            item.clockData.endDaySecond = item.clockData.endDaySecond + 86400;
          }

          delete item.clockData.endTimeData;
        }

        item.clockDataList.map((citem) => {
          delete citem.indexPeriod;
        });
      });

      delete confimForm.clockDataList;

      // 最终确认
      if (confimForm.id) {
        http.post("/bmsAttendanceArrange/update", confimForm).then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");

            this.multipleSelection.map((item) => {
              if (item.id == confimForm.id) {
                item.arrangeDataList = confimForm.arrangeDataList;
              }
            });
            this.tableData.map((item) => {
              if (item.id == confimForm.id) {
                item.arrangeDataList = confimForm.arrangeDataList;
              }
            });

            this.fixedKey = Math.random();

            this.cancelDiglog();

            // 重新获取访客列表
            this.editClassListCopy();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        http
          .post(
            "/bmsAttendanceArrange/add",
            Object.assign(
              {
                creator: this.id,
              },
              confimForm
            )
          )
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");

              this.cancelDiglog();

              // 重新获取班次列表
              this.getClassList();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    // 取消更改班次弹窗
    cancelChange() {
      this.checkedBC = [];
      this.$refs.table.clearSelection();
      this.dialogChange = false;
    },
    // 关闭更改班次提示
    closeTips() {
      this.tipsDialog = false;
    },
    // 关闭更改班次提示
    confirmChangeDiglog() {
      this.dialogFormVisible = true;
      this.tipsDialog = false;
    },
    // 确认更改班次
    confirmChange() {
      if (!this.checkedBC[0]) {
        this.$message.error("请先勾选一个班次");
        return;
      }
      this.dialogChange = false;
      if (!this.currentChecked) {
        this.tableData[this.currentIndex].ischecked = true;
        this.$nextTick(() => {
          this.$refs.itemTable.toggleRowSelection(
            this.tableData[this.currentIndex],
            true
          );
        });
      }

      this.tableData[this.currentIndex].id = this.checkedBC[0].id;
      this.tableData[this.currentIndex].arrangeDataList =
        this.checkedBC[0].arrangeDataList;
      this.tableData[this.currentIndex].name = this.checkedBC[0].name;

      var obj = {
        id: this.checkedBC[0].id,
        index: this.currentIndex,
        ischecked: true,
      };

      var cindex = this.multipleSelection.findIndex(
        (item) => item.index == this.currentIndex
      );
      if (cindex > -1) {
        this.multipleSelection[cindex].id = this.checkedBC[0].id;
      } else {
        this.multipleSelection.push(obj);
        this.multipleSelection.sort(function (a, b) {
          return a.index - b.index;
        });
      }

      console.log(this.multipleSelection);
    },
    // 时间换为分钟
    timeToMinutes(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 60 + minute;
    },

    // -----------------------------------------------
    // 秒转换成时间
    secondsToHourMin(seconds) {
      if (seconds >= 86400) {
        seconds = seconds - 86400;
      }
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
    // 时间换为秒
    timeToSecond(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 3600 + minute * 60;
    },
    // 时间换为秒
    timeToSecond2(time, isTomorrow) {
      const [hour, minute] = time.split(":").map(Number);
      if (isTomorrow) {
        return hour * 3600 + minute * 60 + 86400;
      } else {
        return hour * 3600 + minute * 60;
      }
    },
    formatTime(tt) {
      var arr = [];
      if (tt) {
        tt.map((item) => {
          var start = "";
          var end = "";
          if (item.clockData) {
            start = this.secondsToHourMin(item.clockData.beginDaySecond);
            end = this.secondsToHourMin(item.clockData.endDaySecond);
          }
          arr.push(start + "-" + end);
        });

        return arr.join(",");
      }
    },
    handleCheckboxChange3(value) {
      this.checkedGroup = value;
      if (value == 3) {
        let newArray = this.overtimeType.slice(0, -1);
        this.overtimeTypeCopy = newArray;
      } else {
        this.overtimeTypeCopy = this.overtimeType;
      }
    },
    tableCellSytle() {
      return "background-color: #F5F6FA; color:#5C5C5C;";
    },

    itemHandleSelectionChange(selection, row) {
      selection.sort(function (a, b) {
        return a.index - b.index;
      });
      this.multipleSelection = selection;
      let selected = selection.length && selection.indexOf(row) !== -1;
      row.ischecked = selected;
    },
    selection_change(selection) {
      this.multipleSelection = selection;
      this.multipleSelectionCopy = selection;
      if (this.multipleSelection.length == 7) {
        this.indeterminate = true;
        this.multipleSelection.forEach((item) => {
          if (!item.ischecked) item.ischecked = true;
        });
      } else {
        this.tableData.forEach((item) => {
          if (item.ischecked) item.ischecked = false;
        });
        this.indeterminate = false;
      }
    },
    handleSelectionChange2(val) {
      if (val) {
        this.checkedBC = val;
        if (val.length > 1) {
          this.$refs.table.clearSelection();
          this.$refs.table.toggleRowSelection(val.pop());
        }
      }
    },

    backGroup() {
      this.$router.replace({ path: "/group" });
    },

    selectionChange(list) {
      this.selectionList = [];
      if (list.length > 1) {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleRowSelection(list[list.length - 1]);
      }
      this.selectionList = [list[list.length - 1]];
    },
    changeTime(index, currentID) {
      this.editClasses = Math.random();

      var _this = this;
      http
        .get("/bmsAttendanceArrange/list", {
          pageNum: 1,
          pageSize: 7,
        })
        .then((res) => {
          if (res.code == 200) {
            setTimeout(() => {
              this.shiftList = res.data.list;
              this.dialogChange = true;
              this.currentIndex = index;
              this.currentChecked = currentID.ischecked;
              // if (this.isEdit) {
              this.checkedBC = [];
              if (currentID.ischecked) {
                let index2 = this.timeData.findIndex((item) => {
                  return item.id == currentID.id;
                });
                if (index2 >= 0) {
                  this.checkedBC.push(currentID);
                  if (this.checkedBC.length > 0) {
                    var value = _this.shiftList.find(
                      (item) => item.name == this.checkedBC[0].name
                    );
                    if (value) {
                      _this.$nextTick(() => {
                        _this.$refs.table.clearSelection();
                        _this.$refs.table.toggleRowSelection(value);
                      });
                    }
                  }
                } else {
                  this.checkedBC[0] = currentID;
                }
              }
            }, 500);
          }
        });
    },
    cancelRange() {
      this.dialogRange = false;
    },

    openRange() {
      this.dialogRange = true;
    },
    // ----------------- 节假日 --------------------
    // 获取节假日列表
    getHoliday(page) {
      http
        .get("/bmsHoliday/list", {
          pageNum: page,
          pageSize: 99,
        })
        .then((res) => {
          if (res.code == 200) {
            this.holidaysList = res.data.list;
          }
        });
    },
    // 打开节假日设置
    openlHoliday() {
      this.holidaysDialog = true;
    },
    // 关闭节假日设置
    cancelHoliday() {
      this.holidaysDialog = false;
    },

    // 打开新增节假日弹窗
    openAddHolidays() {
      this.tableKey = Math.random();
      setTimeout(() => {
        this.addholidaysDialog = true;
      }, 200);
    },

    // 关闭新增节假日弹窗
    closeAddHolidays() {
      this.holidaysForm = {};
      this.$refs.addholidays.resetFields();
      this.addholidaysDialog = false;
    },
    // 取消编辑节假日
    cancelChangeHoliday() {
      this.tipsDialog2 = false;
    },
    // 编辑节假日
    confirmChangeHoliday() {
      var form = {};
      form.dayBegin = this.formatDate(this.holidaysForm.dateRange[0]);
      form.dayEnd = this.formatDate(this.holidaysForm.dateRange[1]);
      form.name = this.holidaysForm.name;
      form.id = this.holidaysForm.id;
      http.post("/bmsHoliday/update", form).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.tableKey = Math.random();
          this.tipsDialog2 = false;
          this.closeAddHolidays();

          this.getHoliday(1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 确认新增节假日
    confirmAddHolidays() {
      this.$refs["addholidays"].validate((valid) => {
        if (valid) {
          var form = {};
          form.dayBegin = this.formatDate(this.holidaysForm.dateRange[0]);
          form.dayEnd = this.formatDate(this.holidaysForm.dateRange[1]);
          form.name = this.holidaysForm.name;

          if (this.holidaysForm.id) {
            this.tipsDialog2 = true;
          } else {
            http.post("/bmsHoliday/add", form).then((res) => {
              if (res.code == 200) {
                this.$message.success("新增成功");
                this.tableKey = Math.random();
                this.closeAddHolidays();

                this.getHoliday(1);
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑节假日
    editHolidays(info) {
      var arr = [];
      arr.push(this.formatStandardDate(info.dayBegin));
      arr.push(this.formatStandardDate(info.dayEnd));
      this.$set(this.holidaysForm, "name", info.name);
      this.$set(this.holidaysForm, "id", info.id);
      this.$set(this.holidaysForm, "dateRange", arr);
      setTimeout(() => {
        this.addholidaysDialog = true;
      }, 500);
    },
    // 删除节假日
    deleteHolidays(id) {
      this.holidayId = id;
      this.tipsDialog3 = true;
    },
    // 确认删除节假日
    confirmCancelHoliday() {
      http
        .post("/bmsHoliday/delete", {
          ids: [this.holidayId],
        })
        .then((res) => {
          this.$message.success("删除成功");
          this.tableKey = Math.random();
          this.tipsDialog3 = false;
          this.getHoliday(1);
        });
    },
    // 标准日期转换成普通日期
    formatDate(date) {
      var year = date.getFullYear(); // 获取年份
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，并确保两位数字
      var day = ("0" + date.getDate()).slice(-2); // 获取日期，并确保两位数字
      var formattedDate = year + "-" + month + "-" + day; // 格式化日期为 "YYYY-MM-DD" 形式
      return formattedDate;
    },
    // 普通日期转换成标准时间
    formatStandardDate(string) {
      var parts = string.split("-"); // 将日期字符串按照 "-" 分割成年、月、日部分
      var year = parseInt(parts[0]); // 年份
      var month = parseInt(parts[1]) - 1; // 月份（注意：JavaScript 中月份是从 0 开始的，所以需要减 1）
      var day = parseInt(parts[2]); // 日份

      var date = new Date(year, month, day); // 创建 Date 对象，传入年、月、日参数
      return date;
    },
    // ----------------- 加班规则 --------------------
    // 打开加班规则弹窗
    openOvertimeDialog() {
      var object = JSON.stringify(this.overtimeFormPreserve);
      this.overtimeForm = JSON.parse(object);
      this.overtimeTab = 1;

      setTimeout(() => {
        this.overtimeDialog = true;
      }, 500);
    },
    // 关闭加班规则弹窗
    closeOvertimeDialog() {
      this.overtimeDialog = false;
    },
    // 确认更改规则
    confirmRules() {
      var object = JSON.stringify(this.overtimeForm);
      object = JSON.parse(object);
      var istrue = false;

      for (let key in object) {
        if (object[key].ischecked) {
          if (object[key].type == 2) {
            if (object[key].error) {
              istrue = true;
            }
          }
          if (object[key].time) {
            object[key].restlist.map((item) => {
              if (item.error) {
                istrue = true;
                return;
              }
            });
          }
        }
      }
      if (!istrue) {
        this.overtimeFormPreserve = object;
        this.overtimeDialog = false;
      }
    },
    // 切换加班类型日
    switchOvertimeDay(value) {
      var object = JSON.stringify(this.overtimeForm);
      object = JSON.parse(object);
      var istrue = false;

      for (let key in object) {
        if (object[key].ischecked) {
          if (object[key].type == 2) {
            if (object[key].error) {
              istrue = true;
            }
          }
          if (object[key].time) {
            object[key].restlist.map((item) => {
              if (item.error) {
                istrue = true;
                return;
              }
            });
          }
        }
      }
      if (!istrue) {
        this.overtimeTab = value;
        this.overtimeChecked = true;
      }
    },
    // 切换加班计算方式
    switchOvertimeType(value, type) {
      switch (type) {
        case "weekdayType":
          this.overtimeForm.weekday.type = value;
          break;
        case "restDayType":
          this.overtimeForm.restDay.type = value;
          break;
        case "holidaysType":
          this.overtimeForm.holidays.type = value;
          break;
      }
    },
    // 点击新增(按加班时长扣除)
    addovertimeDeductionTimeList() {
      var arr = [];
      // 获取扣除加班时长的最后一个选项
      var value =
        this.overtimeDeductionTimeList[
          this.overtimeDeductionTimeList.length - 1
        ];
      if (value.topup) {
        for (let index = value.topup + 15; index < 361; index = index + 15) {
          arr.push({
            value: index,
            label: index,
          });
        }

        this.overtimeDeductionTimeList.push({
          topup: value.topup + 15,
          deduction: 5,
          overtimeDeductionTopup: arr, //加满选项
        });
      } else {
        for (let index = 15; index < 361; index = index + 15) {
          arr.push({
            value: index,
            label: index,
          });
          this.overtimeDeductionTimeList.push({
            topup: 30,
            deduction: 5,
            overtimeDeductionTopup: arr, //加满选项
          });
        }
      }
    },
    // 删除(按加班时长扣除)
    deleteovertimeDeductionTimeList(index) {
      this.overtimeDeductionTimeList.splice(index, 1);
      var length = this.overtimeDeductionTimeList.length;
      if (length == 1) {
        var arr = [];
        for (let index = 15; index < 361; index = index + 15) {
          arr.push({
            value: index,
            label: index,
          });
        }

        this.overtimeDeductionTimeList[0].overtimeDeductionTopup = arr;
      }
    },
    // 改变加满
    changeTopup(value) {
      var currentIndex = this.overtimeDeductionTimeList.findIndex(
        (item) => item.topup == value
      );
      // 改变了当前的加满
      var current = this.overtimeDeductionTimeList[currentIndex];
      var arr = [];
      for (let index = current.topup + 15; index < 361; index = index + 15) {
        arr.push({
          label: index,
          value: index,
        });
      }
      // 下一个是否满足
      var nextValue = this.overtimeDeductionTimeList[currentIndex + 1];
      nextValue.overtimeDeductionTopup = arr;
      if (nextValue.topup < current.topup) {
        nextValue.tips = "时长应大于上一个条件";
      }

      if (currentIndex != 0) {
        // 上一个值
        var last = this.overtimeDeductionTimeList[currentIndex - 1];
        if (current.topup > last.topup) {
          delete current.tips;
        }
      }
    },
    // 改变扣除
    changeDeduction(event, index) {
      var currentValue = this.overtimeDeductionTimeList[index];
      if (event > currentValue.topup) {
        currentValue.tips2 = "扣除时长不能超过[加班满]时长";
      } else {
        delete currentValue.tips2;
      }
    },
    // 点击新增(按休息时段扣除)
    restAdd(arr) {
      arr.push({
        isbeginTomorrow: false,
        isendTomorrow: false,
        beginDaySecond: "9:00",
        endDaySecond: "12:00",
      });
    },
    // 删除(按休息时段扣除)
    restDelete(arr, index) {
      arr.splice(index, 1);
    },
    // 判断是在哪个类型
    dateType(type) {
      switch (type) {
        case 1:
          return "按加班审批时长计算";
        case 2:
          return "按打卡时长计算";
        case 3:
          return "按审批和打卡时长计算";
      }
    },
    // 处理要提交的规则列表
    handleRule() {
      var object = JSON.stringify(this.overtimeFormPreserve);
      object = JSON.parse(object);
      var newObject = {
        workOvertimeConfigItemData: {},
        restOvertimeConfigItemData: {},
        holidayOvertimeConfigItemData: {},
      };
      // 工作日处理
      if (object.weekday.ischecked) {
        delete object.weekday.ischecked;
        newObject.workOvertimeConfigItemData = {};
        if (!object.weekday.time) {
          object.weekday.restlist = [];
        }

        object.weekday.restlist.map((item) => {
          item.beginDaySecond = this.timeToSecond2(
            item.beginDaySecond,
            item.isbeginTomorrow
          );
          item.endDaySecond = this.timeToSecond2(
            item.endDaySecond,
            item.isendTomorrow
          );
          delete item.isbeginTomorrow;
          delete item.isendTomorrow;
        });
        switch (object.weekday.type) {
          case 1:
            newObject.workOvertimeConfigItemData.overtimeConfigItemApplyTimeData =
              object.weekday;
            newObject.workOvertimeConfigItemData.overtimeConfigItemApplyTimeData.clockDataList =
              object.weekday.restlist;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.type;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.time;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.restlist;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.minTime;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.maxTime;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.beginTime;
            break;
          case 2:
            if (this.checkedGroup == 3) {
              delete object.weekday.beginTime;
            } else {
              object.weekday.beginTime =
                parseInt(object.weekday.beginTime) * 60;
            }
            object.weekday.maxTime = parseInt(object.weekday.maxTime) * 60;
            object.weekday.minTime = parseInt(object.weekday.minTime) * 60;
            newObject.workOvertimeConfigItemData.overtimeConfigItemClockTimeData =
              object.weekday;
            newObject.workOvertimeConfigItemData.overtimeConfigItemClockTimeData.clockDataList =
              object.weekday.restlist;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.type;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.time;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.restlist;
            break;
          case 3:
            newObject.workOvertimeConfigItemData.overtimeConfigItemMixTimeData =
              object.weekday;
            newObject.workOvertimeConfigItemData.overtimeConfigItemMixTimeData.clockDataList =
              object.weekday.restlist;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.type;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.time;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.restlist;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.minTime;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.maxTime;
            delete newObject.workOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.beginTime;
            break;
        }
      }
      // 休息日处理
      if (object.restDay.ischecked) {
        delete object.restDay.ischecked;
        newObject.restOvertimeConfigItemData = {};
        if (!object.restDay.time) {
          object.restDay.restlist = [];
        }
        object.restDay.restlist.map((item) => {
          item.beginDaySecond = this.timeToSecond2(
            item.beginDaySecond,
            item.isbeginTomorrow
          );
          item.endDaySecond = this.timeToSecond2(
            item.endDaySecond,
            item.isendTomorrow
          );
          delete item.isbeginTomorrow;
          delete item.isendTomorrow;
        });
        switch (object.restDay.type) {
          case 1:
            newObject.restOvertimeConfigItemData.overtimeConfigItemApplyTimeData =
              object.restDay;
            newObject.restOvertimeConfigItemData.overtimeConfigItemApplyTimeData.clockDataList =
              object.restDay.restlist;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.type;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.time;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.restlist;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.minTime;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.maxTime;
            break;
          case 2:
            object.restDay.maxTime = parseInt(object.restDay.maxTime) * 60;
            object.restDay.minTime = parseInt(object.restDay.minTime) * 60;
            newObject.restOvertimeConfigItemData.overtimeConfigItemClockTimeData =
              object.restDay;
            newObject.restOvertimeConfigItemData.overtimeConfigItemClockTimeData.clockDataList =
              object.restDay.restlist;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.type;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.time;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.restlist;
            break;
          case 3:
            newObject.restOvertimeConfigItemData.overtimeConfigItemMixTimeData =
              object.restDay;
            newObject.restOvertimeConfigItemData.overtimeConfigItemMixTimeData.clockDataList =
              object.restDay.restlist;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.type;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.time;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.restlist;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.minTime;
            delete newObject.restOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.maxTime;
            break;
        }
      }
      // 节假日处理
      if (object.holidays.ischecked) {
        delete object.holidays.ischecked;
        newObject.holidayOvertimeConfigItemData = {};
        if (!object.holidays.time) {
          object.holidays.restlist = [];
        }
        object.holidays.restlist.map((item) => {
          item.beginDaySecond = this.timeToSecond2(
            item.beginDaySecond,
            item.isbeginTomorrow
          );
          item.endDaySecond = this.timeToSecond2(
            item.endDaySecond,
            item.isendTomorrow
          );
          delete item.isbeginTomorrow;
          delete item.isendTomorrow;
        });
        switch (object.holidays.type) {
          case 1:
            newObject.holidayOvertimeConfigItemData.overtimeConfigItemApplyTimeData =
              object.holidays;
            newObject.holidayOvertimeConfigItemData.overtimeConfigItemApplyTimeData.clockDataList =
              object.holidays.restlist;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.type;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.time;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.restlist;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.minTime;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemApplyTimeData.maxTime;
            break;
          case 2:
            object.holidays.maxTime = parseInt(object.holidays.maxTime) * 60;
            object.holidays.minTime = parseInt(object.holidays.minTime) * 60;
            newObject.holidayOvertimeConfigItemData.overtimeConfigItemClockTimeData =
              object.holidays;
            newObject.holidayOvertimeConfigItemData.overtimeConfigItemClockTimeData.clockDataList =
              object.holidays.restlist;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.type;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.time;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemClockTimeData.restlist;
            break;
          case 3:
            newObject.holidayOvertimeConfigItemData.overtimeConfigItemMixTimeData =
              object.holidays;
            newObject.holidayOvertimeConfigItemData.overtimeConfigItemMixTimeData.clockDataList =
              object.holidays.restlist;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.type;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.time;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.restlist;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.minTime;
            delete newObject.holidayOvertimeConfigItemData
              .overtimeConfigItemMixTimeData.maxTime;
            break;
        }
      }

      return newObject;
    },
    // ----------------- 部门 --------------------
    // 获取部门列表
    getDeptlist(id) {
      http
        .get("/umsDepartment/list/down", {
          departmentId: id,
        })
        .then((res) => {
          if (res.code == 200) {
            var index = this.crumbscopyList.findIndex(
              (item) => item.departmentId == id
            );
            res.data.map((item) => {
              item.ischecked = false;
            });

            res.data.map((item) => {
              item.ischecked = this.judgeStaus(item);
            });
            if (index > -1) {
              this.crumbscopyList[index].child = res.data;
            }
            this.deptList = res.data;
          }
        });
    },
    // 获取搜索结果
    seachResult() {
      if (this.search == "") {
        return;
      }
      http
        .get("/umsDepartment/list/down", {
          userName: this.search,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isSearchResult = true;
            res.data.map((item) => {
              item.ischecked = this.judgeStaus(item);
            });
            this.deptList = res.data;
          }
        });
    },
    clearName() {
      this.search = "";
    },

    // 点击面包屑部门
    breadDept(item, index) {
      this.crumbsList.splice(index + 1, this.crumbsList.length - 1);

      var index = this.checkDepartment.findIndex(
        (item) => item.departmentId == ""
      );

      if (item.name == "全部") {
        this.querySubordinates2("");
        // if (index > -1) {
        //   this.deptList = [
        //     {
        //       name: this.topleave[0].departmentName,
        //       departmentId: null,
        //       ischecked: true,
        //     },
        //   ];
        // } else {
        //   this.deptList = [
        //     {
        //       name: this.topleave[0].departmentName,
        //       departmentId: "",
        //       ischecked: false,
        //     },
        //   ];
        // }
      } else {
        this.getDeptlist(item.departmentId);
      }
    },
    // 查询下级
    querySubordinates(item) {
      this.crumbsList.push({
        name: item.name,
        departmentId: item.departmentId,
      });
      // 深层次的面包屑
      this.crumbscopyList.push({
        name: item.name,
        departmentId: item.departmentId,
      });

      this.getDeptlist(item.departmentId);
    },
    // 公司获取所有部门
    querySubordinates2(item) {
      this.getDeptlist(item);
    },

    // 判断是否是选中状态
    judgeStaus(value) {
      // 如果是人员
      if (value.userId || value.jobDepartment) {
        var index = this.checkPersion.findIndex(
          (item) => item.userId == value.userId || value.id
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        var index = this.checkDepartment.findIndex(
          (item) => item.departmentId == value.departmentId
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 选择部门
    selectDept2(item) {
      item.path = [];
      // 处理所选的部门或人员,加路径部门
      // this.crumbsList.map((value) => {
      //   item.path.push(value.departmentId);
      // });
      // console.log(item)
      // if (item.departmentId == '' && !item.userId) {
      //   this.querySubordinates2(item)
      // }
      // return
      // 如果是选中状态
      if (item.ischecked) {
        // 先判断是否在这路径上(人)
        if (item.userId) {
          // 如果是人
          this.checkPersion.push(item);
        } else {
          // var arr = [];
          // var arr2 = [];
          // 部门
          // for (let index = 0; index < this.checkDepartment.length; index++) {
          //   if (!this.checkDepartment[index].isold) {

          //     // if (this.checkDepartment[index].path) {
          //     for (
          //       let cindex = 0;
          //       cindex < this.checkDepartment[index].path.length;
          //       cindex++
          //     ) {
          //       if (
          //         this.checkDepartment[index].path[cindex] == item.departmentId
          //       ) {
          //         arr.push(this.checkDepartment[index]);
          //       }
          //     }
          //   }
          // }

          // for (let index = 0; index < this.checkPersion.length; index++) {
          //   if (!this.checkPersion[index].isold) {
          //     // if (this.checkPersion[index].path) {
          //     for (
          //       let cindex = 0;
          //       cindex < this.checkPersion[index].path.length;
          //       cindex++
          //     ) {
          //       if (this.checkPersion[index].path[cindex] == item.departmentId) {
          //         arr2.push(this.checkPersion[index]);
          //       }
          //     }
          //   }

          // }
          this.checkDepartment.push(item);
          // console.log(this.checkDepartment)
          // let ids = arr.map((item) => item.departmentId);
          // this.checkDepartment = this.checkDepartment.filter(
          //   (item) => !ids.includes(item.departmentId)
          // );

          // let ids2 = arr2.map((item) => item.departmentId);
          // this.checkPersion = this.checkPersion.filter(
          //   (item) => !ids2.includes(item.departmentId)
          // );
        }
      } else {
        // 取消选中(如果是人员)
        if (item.userId) {
          var index = this.checkPersion.findIndex(
            (value) => value.userId == item.userId
          );
          if (index > -1) {
            this.checkPersion.splice(index, 1);
          }
        } else {
          // 如果是部门
          var index = this.checkDepartment.findIndex(
            (value) => value.departmentId === item.departmentId
          );
          if (index > -1) {
            this.checkDepartment.splice(index, 1);
          }
        }
      }

      this.checkList = [...this.checkDepartment, ...this.checkPersion];
    },

    // 删除所选部门
    delectDept(item) {
      if (item.userId) {
        var onslie = this.checkPersion.findIndex(
          (value) => value.userId == item.userId
        );
        this.checkPersion.splice(onslie, 1);

        // 如果在当前列表
        var index = this.deptList.findIndex(
          (value) => value.userId == item.userId
        );
        if (index > -1) {
          this.deptList[index].ischecked = false;
        }
      } else {
        var onslie = this.checkDepartment.findIndex(
          (value) => value.departmentId == item.departmentId
        );
        this.checkDepartment.splice(onslie, 1);

        // 如果在当前列表
        var index = this.deptList.findIndex(
          (value) => value.departmentId == item.departmentId
        );
        if (index > -1) {
          this.deptList[index].ischecked = false;
        }
      }

      this.checkList = [...this.checkPersion, ...this.checkDepartment];
    },
    // 清除所有选择的部门
    clearall() {
      this.deptList.map((item) => (item.ischecked = false));
      this.checkList = [];
      this.checkDepartment = [];
      this.checkPersion = [];
    },
    // ----------------- 补卡 --------------------
    // 处理补卡数组
    handelReplacement() {
      var object = JSON.stringify(this.replacementsForm);
      object = JSON.parse(object);
      var key = ["permitAbsence", "permitEarly", "permitLate", "permitOther"];
      var newObeject = {};
      if (object.dayLimit == 0) {
        delete object.dayLimit;
      }
      if (object.dayMax == 0) {
        delete object.dayMax;
      }
      if (object.monthMax == 0) {
        delete object.monthMax;
      }
      // // 遍历数组a
      key.forEach(function (element) {
        // 检查当前元素是否在数组b中
        newObeject[element] = object.type.includes(element);
      });

      delete object.type;

      var result = { ...object, ...newObeject };

      // // 判断对象中的值是否都等于 false
      var allValuesFalse = this.areAllBooleansFalse(result);

      if (allValuesFalse) {
        return null;
      } else {
        return result;
      }
    },
    handelReplacement2(obj) {
      var object = JSON.stringify(obj);
      object = JSON.parse(object);
      var key = ["permitAbsence", "permitEarly", "permitLate", "permitOther"];
      var newObeject = {};
      if (object.dayLimit == 0) {
        delete object.dayLimit;
      }
      if (object.dayMax == 0) {
        delete object.dayMax;
      }
      if (object.monthMax == 0) {
        delete object.monthMax;
      }
      // // 遍历数组a
      key.forEach(function (element) {
        // 检查当前元素是否在数组b中
        newObeject[element] = object.type.includes(element);
      });

      delete object.type;

      var result = { ...object, ...newObeject };

      // // 判断对象中的值是否都等于 false
      var allValuesFalse = this.areAllBooleansFalse(result);

      if (allValuesFalse) {
        return null;
      } else {
        return result;
      }
    },
    // 判断对象是否都为false
    areAllBooleansFalse(obj) {
      // 遍历对象的所有可枚举属性
      for (let key in obj) {
        // 忽略非布尔值属性（例如，跳过数字）
        if (typeof obj[key] !== "boolean") {
          continue;
        }
        // 如果发现任何布尔值为true，则返回false
        if (obj[key] === true) {
          return false;
        }
      }
      // 如果没有发现任何布尔值为true，则返回true
      return true;
    },

    // --------------------------------------------
    searchUserList() {
      if (this.checkList <= 0) {
        this.$message.error("请先勾选部门／员工");
        return;
      }
      var arr2 = []; //部门
      var arr3 = []; //人员
      this.checkDepartment.map((item) => {
        arr2.push(item.departmentId);
      });
      this.checkPersion.map((item) => {
        arr3.push(item.userId);
      });

      var _this = this;
      (_this.filtrateResult = []),
        (_this.prangge = {
          departmentList: arr2,
          userList: arr3,
        });
      Object.keys(_this.prangge).forEach((item) => {
        if (_this.prangge[item].length == 0) delete _this.prangge[item];
      });
      let list = [];
      this.checkDepartment.map((item) => {
        list.push({ id: item.departmentId, name: item.name });
      });
      this.checkPersion.map((item) => {
        list.push({ id: item.userId, name: item.name });
      });
      // _this.filtrateResult = { ...list }
      _this.filtrateResult = JSON.parse(JSON.stringify(list));
      _this.copyfiltrateResult = JSON.parse(JSON.stringify(list));
      _this.dialogRange = false;
      _this.isFiltrate = true;
    },
    formatRecord(list) {
      let arr = [];
      list.map((item) => arr.push(item.name));
      return arr.join(",");
    },

    delectscreening(citem) {
      this.filtrateResult = this.filtrateResult.filter((item) => {
        return item.id != citem.id;
      });
      this.filtrateResult.length == 0 ? (this.isFiltrate = false) : "";
    },

    delectscreening2() {
      this.groupPeople = "";
      this.groupForm.admin = "";
    },
    // 处理数组
    handlearr(arr) {
      if (arr) {
        var length = 0;
        var width = 0;
        for (let index = 0; index < arr.length; index++) {
          width += this.getTextWidth(arr[index].name);
          if (width > 250) {
            length = index;
            break;
          }
        }

        if (length == 0) {
          return arr;
        } else {
          return arr.slice(0, length);
        }
      }
    },
    // 省略显示的值
    omitDisplay(arr) {
      if (arr) {
        var length = 0;
        var width = 0;
        for (let index = 0; index < arr.length; index++) {
          width += this.getTextWidth(arr[index].name);

          if (width > 250) {
            length = index;
            break;
          }
        }

        if (length == 0) {
          return [];
        } else {
          this.peoList = arr.slice(length, arr.length).length;
          return arr.slice(length, arr.length);
        }
      }
    },
    getTextWidth(text) {
      // 创建临时元素
      const _span = document.createElement("span");
      // 放入文本
      _span.innerText = text;
      // 设置文字大小
      _span.style.fontSize = "14px";
      // span元素转块级
      _span.style.position = "absolute";
      // span放入body中
      document.body.appendChild(_span);
      // 获取span的宽度
      let width = _span.offsetWidth;
      // 从body中删除该span
      document.body.removeChild(_span);
      // 返回span宽度
      return width + 16;
    },

    // 再点击选择框，取消选择
    handleCheckboxChange(checkedValue, index, value) {
      var arr = [];
      if (checkedValue) {
        // 如果选择选择框
        arr = [];
        arr.push(value);
        this.$set(
          this.classesForm.arrangeDataList[index],
          "beginChoosearr",
          arr
        );
      } else {
        var index = arr.findIndex((item) => item == value);
        if (index) {
          arr.slice(index, 1);
        }
      }
    },
    // 再点击选择框，取消选择
    handleCheckboxChange2(checkedValue, index, value) {
      var arr = [];
      if (checkedValue) {
        // 如果选择选择框
        arr = [];
        arr.push(value);
        this.$set(this.classesForm.arrangeDataList[index], "endChoosearr", arr);
      } else {
        var index = arr.findIndex((item) => item == value);
        if (index) {
          arr.slice(index, 1);
        }
      }
    },

    // 当前时间减一分钟
    subtractMinute(timeString) {
      var parts = timeString.split(":");
      var hours = parseInt(parts[0], 10);
      var minutes = parseInt(parts[1], 10);

      minutes -= 1;

      // 如果分钟变为负数，则从前一小时借位
      if (minutes < 0) {
        minutes += 60;
        hours -= 1;

        // 如果小时变为负数（即原本是00:00），则将其设置为23
        if (hours < 0) {
          hours = 23;
        }
      }

      // 保证小时和分钟都是两位数
      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");

      return hours + ":" + minutes;
    },

    // 时分转换成时间戳
    timeToTimestamp(hours, minutes) {
      const date = new Date(); // 获取当前日期
      date.setHours(hours); // 设置小时
      date.setMinutes(minutes); // 设置分钟
      date.setSeconds(0); // 将秒和毫秒设置为0，保持时间戳整数

      // 将日期对象转换为时间戳（毫秒数）
      const timestamp = date.getTime();

      return timestamp;
    },

    // 判断两个时间段谁更大
    formatDiffent(dateString1, dateString2, beginTomorrow, endTomorrow) {
      // 将时间字符串拆分为小时和分钟
      var time1Array = dateString1.split(":");
      var time2Array = dateString2.split(":");
      var newBegin = 0; //开始时间
      var endBegin = 0; //结束时间

      if (beginTomorrow) {
        newBegin = parseInt(time1Array[0]) * 3600 + 86400;
      } else {
        newBegin = parseInt(time1Array[0]) * 3600;
      }
      if (endTomorrow) {
        endBegin = parseInt(time2Array[0]) * 3600 + 86400;
      } else {
        endBegin = parseInt(time2Array[0]) * 3600;
      }

      // 计算总秒数
      var time1Seconds = newBegin + parseInt(time1Array[1]) * 60;
      var time2Seconds = endBegin + parseInt(time2Array[1]) * 60;

      if (time1Seconds >= time2Seconds) {
        return false;
      } else {
        return true;
      }
    },

    resetBegin(value) {
      if (!value) {
        this.freeShiftForm.beginSecond = "6:00";
      }
    },
  },
};
</script>
<style scoped>
.group {
  width: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f4f7fc;
  height: 100%;
}

.group_head {
  font-size: 14px;
  margin-bottom: 16px;
}

.group_wrapper {
  /* width: calc(100vw - 290px); */
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}

.group_search_wrapper {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  align-items: center;
}

.title_icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.group_title {
  font-size: 16px;
  color: #5c5c5c;
}

.group_back {
  font-size: 16px;
  color: #1a1a1a;
  display: inline-block;
}
.groupForm {
  padding-bottom: 30px;
  box-sizing: border-box;
}
::v-deep .groupForm .el-form-item__label {
  color: #1a1a1a;
  font-size: 15px;
  text-align: left;
  float: none;
  word-break: break-word;
}

::v-deep .groupForm .el-input {
  width: 413px;
}

::v-deep .groupForm_label .el-form-item__label::before {
  content: "*";
  color: #f0142f;
  /* margin-left: -8px; */
  font-size: 18px;
  padding-top: 15px;
  margin-right: 2px;
}

.groupForm_content {
  color: #1a1a1a;
  font-size: 15px;
  margin: 31px 0 14px;
  position: relative;
  padding-left: 12px;
  box-sizing: border-box;
}
.groupForm_content::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "*";
  color: #f0142f;
  /* margin-left: -8px; */
  font-size: 18px;
}
.cycle_list_popover {
  border: none;
  height: 28px;
  line-height: 28px;
  z-index: 999;
}

.cycle_list_up {
  width: 19px;
  height: 19px;
  margin-right: 0;
  margin-left: auto;
}

/* ::v-deep .group_form_wrapper .el-checkbox__inner {
  width: 26px;
  height: 26px;
} */
/* ::v-deep .group .el-checkbox__inner {
  width: 16px;
  height: 16px;
}

::v-deep .group .el-checkbox__inner::after {
  border: 2.5px solid #fff;
  border-left: 0;
  border-top: 0;
}

::v-deep .group .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(0.9);
} */

.groupForm_content2 {
  color: #1a1a1a;
  font-size: 15px;
  margin-bottom: 15px;
  padding-left: 12px;
  box-sizing: border-box;
  position: relative;
}
.groupForm_content2::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "*";
  color: #f0142f;
  /* margin-left: -8px; */
  font-size: 18px;
}
.groupForm_tip {
  font-size: 16px;
  color: #9a9a9a;
  margin-bottom: 20px;
}

/* .groupForm_t ::v-deep .el-checkbox__inner {
  width: 16px;
  height: 16px;
} */

.groupForm_btn {
  border: none;
  background: none;
  color: #0058ff;
  cursor: pointer;
  font-size: 15px;
}

.group_form_wrapper ::v-deep .el-table .el-table__cell {
  background-color: #f5f6fa;
  color: #5c5c5c;

  font-size: 15px;
}

.cancel_button {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-right: 30px;
  border: 1px solid #d5d7e3;
}

.group_add {
  margin-right: 10px;
  margin-left: auto;
}
.group_wrapper {
  overflow-y: scroll;
  position: relative;
  flex: 1;
}
.group_form_wrapper {
  position: absolute;
  padding: 10px 40px;
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;
}

.group_form {
  white-space: nowrap;
  font-size: 16px;
}

.form_title {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: bold;
  margin-bottom: 30px;
}

::v-deep .group_wrapper .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1a1a1a;
}

::v-deep .group_wrapper .el-checkbox__label {
  color: #1a1a1a;
  font-size: 15px;
}

.group_form_type {
  font-size: 16px;
  color: #9a9a9a;
  margin-left: 20px;
  margin-top: -10px;
}

.group_type {
  display: inline-block;
  width: 48px;
  height: 26px;
  line-height: 26px;
  background: #e0ecff;
  opacity: 1;
  text-align: center;
  color: #0058ff;
}

/* ::v-deep .elTable .el-table__header-wrapper .el-checkbox {
  display: none;
} */

::v-deep .elTable .el-table__cell {
  color: #1a1a1a;
  font-size: 15px;
}

/* .elTable ::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
} */

.delectdiglog {
  padding: 0 30px;
}

.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}

.delectdiglog ::v-deep .el-table__empty-text {
  margin: 0;
  text-align: center;
}

.delectdiglog ::v-deep .el-table__empty-block {
  width: 100% !important;
}

.diglog_h {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px;
  border-bottom: 1px solid #cfd4e8;
  color: #1a1a1a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.diglog_operation {
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-range-body {
  height: 583px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
  margin: 0 10px;
  display: flex;
}

.dialog-range-left {
  width: 50%;
  border-right: 1px solid #d0d0d0;
  padding: 20px;
}

.range_search {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.range_search:focus-within {
  border-color: #0096fa;
}

::v-deep .range_search .el-input__inner {
  height: 36px;
  border: none;
}

.range-left-all {
  padding-top: 20px;
  margin-bottom: 10px;
}

.crumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #5c5c5c;
}

.range-left-user {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  line-height: 40px;
}

.dialog-range-right {
  padding: 20px;
  width: 50%;
}

.right-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  font-size: 15px;
  max-height: 500px;
  overflow-y: scroll;
}
.right-list::-webkit-scrollbar {
  display: none;
}

.right-list2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 32px;
  margin-top: 15px;
  font-size: 15px;
  height: 528px;
}

.selectoption_single {
  background: #e0ecff;
  border-radius: 4px;
  padding: 6px;
  color: #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 16px;
  margin-bottom: 5px;
  line-height: 30px;
}

.selectoption_none {
  height: 490px;
  width: 100%;
  font-size: 15px;
  text-align: center;

  line-height: 490px;
}

.icon-close {
  color: #e0ecff;
  border-radius: 10px;
  margin-left: 5px;
}

.newpagination {
  display: flex;
  margin-top: 0px;
  justify-content: space-between;
}

.newpagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .newpagination .el-pagination {
  margin: 0 20px 30px auto;
}

.distribution_checkbox {
  height: 400px;
  overflow: auto;
}

.distribution_checkbox_option {
  display: flex;
  flex-direction: row;
  line-height: 40px;
  font-size: 14px;
}

.distribution_checkbox_link {
  margin-left: auto;
  margin-right: 0px;
  font-size: 12px;
}

.selectoption_t {
  height: 36px;
  display: flex;
  justify-content: space-between;
}

.discolour {
  color: #0058ff;
}

.filtrate {
  min-width: 413px;
}

.filtrate_input {
  width: 410px;
  display: inline-block;
  height: 40px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

.filtrate_single {
  padding: 5px 6px;
  color: #0058ff;
  font-size: 12px;
  display: flex;
  align-items: center;

  border-radius: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filtrate_single_a {
  padding: 0 5px;
  height: 30px;
  /* width: auto; */
  background: #e0ecff;
  margin-right: 7px;
  line-height: 30px;
}

.filtrate_single_c {
  padding-left: 3px;
  height: 30px;
  width: auto;
  background: #e0ecff;
  margin-right: 7px;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.filtrate_single_p {
  /* margin: 5px 3px; */
  padding: 5px 6px;
  color: #0058ff;
  font-size: 12px;
  display: flex;
  align-items: center;

  border-radius: 4px;
}

.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.selectItem {
  display: flex;
  align-items: center;
}
::v-deep .selectItem > .el-checkbox__label {
  font-size: 1rem;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep .device_search_t .el-input__inner {
  background: url("@/assets/up.png") no-repeat center center;
  background-size: 19px;
  background-position: 385px 10px;
  padding: 0 0px 0 auto;
  box-sizing: border-box;
}

::v-deep .el-link .is-disabled:focus {
  color: wheat;
}

::v-deep .right-list .selectoption_single {
  margin-bottom: 11px;
}

.classes_form {
  padding: 30px;
  box-sizing: border-box;
}

::v-deep .classes_name {
  width: 413px;
}
::v-deep .el-radio__label {
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}

::v-deep .el-radio .el-radio__input.is-checked .el-radio__inner {
  color: #0058ff;
  background: #0058ff;
}

.classes_form_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .classes_form_diglog {
  width: 100px;
  margin-right: 30px;
}

::v-deep .classdiglog .el-dialog {
  padding: 0 !important;
}

::v-deep .el-form-item__label {
  font-size: 16px;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1a1a1a;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #e0ecff;
  color: #0058ff;
  border-color: #0058ff;
}

/* ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
  border: none;
  line-height: 2;
  background: none;
} */

/* ::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
  border: none;
  line-height: 2;
  background: none;
} */

/* ::v-deep .el-input-number .el-icon-arrow-down::before {
  font-size: 0;
  content: url("@/assets/check/bottom.png") !important;
} */

::v-deep .el-dialog__title {
  font-size: 16px;
}

::v-deep .el-checkbox__label {
  color: #1a1a1a;
  font-weight: normal;
  font-size: 15px;
  line-height: 40px;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #1a1a1a;
}

::v-deep .el-input-number__decrease {
  height: 50% !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

::v-deep .el-input-number__increase {
  height: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  content: "";
  border: 2px solid white;
  border-left: 0;
  border-top: 0;
}

::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
  content: "";
  border: 2px solid #9d9d9d;
  border-left: 0;
  border-top: 0;
}
.groupForm_t {
  width: 530px;
}
.groupForm_type {
  display: flex;
  align-items: center;
}
.groupForm_type_single {
  padding: 16px 14px;
  box-sizing: border-box;
  margin-right: 15px;
  width: 220px;
  cursor: pointer;
  position: relative;
  height: 96px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d0d0d0;
}
.groupForm_type_single_title {
  font-size: 16px;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 8px;
}
.groupForm_type_single_tips {
  font-size: 12px;
  color: #1a1a1a;
}
.groupForm_type_single_select {
  background: #e8f4ff;
  border-color: #0058ff;
}
.groupForm_type_icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
}
.holidays {
  margin-top: 31px;
}
.holidays_title {
  font-size: 15px;
  color: #1a1a1a;
  font-weight: bold;
}
.holidays_setting {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.holidays_set {
  color: #0058ff;
  margin-left: 20px;
  cursor: pointer;
}
.overtime {
  margin-top: 40px;
  font-size: 16px;
  color: #1a1a1a;
}
.overtime_detail {
  display: flex;
  margin-top: 13px;
  align-items: center;
}
.overtime_name {
  font-size: 15px;
  color: #1a1a1a;
}
.overtime_set {
  width: 80px;
  height: 36px;
  font-size: 14px;
  color: #7e84a3;
  line-height: 36px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
}
.overtime_tips {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  width: 425px;
  height: 119px;
  background: #f5f6fa;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d5d7e3;
}
.overtime_settips {
  display: flex;
  font-size: 15px;
  align-items: center;
}
.overtime_settips_other {
  color: rgba(26, 26, 26, 0.6);
  margin-left: 20px;
}
.replacement {
  margin-top: 40px;
}
.replacement_checked {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
}
.replacement_checked_tips {
  margin-left: 20px;
}
.replacement_setting {
  margin-top: 20px;
  padding-top: 30px;
  box-sizing: border-box;
  width: 1019px;
  height: 290px;
  background: #f5f6fa;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d5d7e3;
}
.replacement_setting_top {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 15px;
  color: #1a1a1a;
}
.replacement_setting_title {
  position: relative;
  width: 171px;
  text-align: right;
  margin-right: 23px;
}
.replacement_setting_title2 {
  width: 171px;
  text-align: right;
  margin-right: 23px;
}
.replacement_setting_title::after {
  position: absolute;
  right: 70px;
  top: 0;
  content: "*";
  color: #f0142f;
  /* margin-left: -8px; */
  font-size: 18px;
}
.replacement_setting_title3 {
  position: relative;
  width: 171px;
  text-align: right;
  margin-right: 23px;
}
.replacement_setting_title3::after {
  position: absolute;
  right: 50px;
  top: 0;
  content: "*";
  color: #f0142f;
  /* margin-left: -8px; */
  font-size: 18px;
}
::v-deep .replacement_setting .el-select {
  width: 100px;
  height: 36px;
  font-size: 14px;
  color: #1a1a1a;
}
::v-deep .replacement_setting .el-input {
  width: 100%;
  height: 100%;
}
::v-deep .replacement_setting .el-input__inner {
  width: 100%;
  height: 100%;
}
.replacement_setting_tips {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  margin-left: 16px;
}
.class_tips {
  margin-top: 31px;
}
.class_custom {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  margin-top: 14px;
}
.free_commuting {
  width: 1019px;
  height: 242px;
  background: #f5f6fa;
  margin-top: 14px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d5d7e3;
  padding-top: 30px;
  box-sizing: border-box;
}
::v-deep .free_commuting .el-select {
  width: 120px;
  height: 36px;
  font-size: 14px;
  color: #1a1a1a;
}
::v-deep .free_commuting .el-input {
  width: 120px;
  height: 36px;
}
::v-deep .free_commuting .el-input__inner {
  width: 100%;
  height: 100%;
}
.replacement_setting_every {
  margin-right: 10px;
}
.replacement_setting_new {
  margin-left: 10px;
}
.newpagination {
  margin-top: 19px;
}
.diglog_search {
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 14px 0;
  justify-content: space-between;
}
.diglog_search_input {
  width: 260px;
  height: 36px;
  display: flex;
  padding: 0 12px 0 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
}
.diglog_add {
  width: 80px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  font-size: 14px;
  color: #0058ff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.diglog_search_input_l {
  width: 200px;
  height: 90%;
  font-size: 15px;
  border: none;
}
::v-deep .diglog_search_input_l .el-input__inner {
  height: 100%;
  border: none;
  padding: 0;
}
.diglog_search_icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.timeStyle {
  width: 110px;
  height: 29px;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 29px;
  font-size: 15px;
  color: #1a1a1a;
}
.holidays_heade {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 35px;
}
.holidays_add {
  width: 80px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #7e84a3;
}
.addholidays {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 37px 0 0 40px;
  box-sizing: border-box;
  height: 500px;
}
::v-deep .addholidays .el-input {
  width: 413px;
  height: 40px;
}
.overtime_frame {
  padding: 0 80px;
  box-sizing: border-box;
}
.overtime_tab {
  margin-top: 40px;
}
.overtime_tab {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.overtime_tab_single {
  margin-right: 60px;
  height: 100%;
  cursor: pointer;
}
.overtime_tab_single_select {
  color: #0058ff;
  position: relative;
}
.overtime_tab_single_select::after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  left: 0;
  height: 3px;
  background: #0058ff;
}
.overtime_check {
  margin: 33px 0 42px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
}
.overtime_check_tips {
  margin-left: 20px;
}
.overtime_type {
  display: flex;
  align-items: center;
}
.overtime_type_sinlge {
  width: 220px;
  height: 96px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d0d0d0;
  margin-right: 15px;
  padding: 16px 14px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.overtime_type_name {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: bold;
  margin-bottom: 8px;
}
.overtime_type_tips {
  font-size: 12px;
  color: rgba(26, 26, 26, 0.69);
}
.overtime_type_select_back {
  border-color: #0058ff;
  background: #e8f4ff;
}
.overtime_type_select {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
}
.overtime_rule_titime {
  font-size: 15px;
  color: #1a1a1a;
  margin: 30px 0 20px;
}
.overtime_rule {
  width: 100%;
  background: #f5f6fa;
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d5d7e3;
  padding: 3px 0 3px 22px;
  box-sizing: border-box;
  margin-bottom: 106px;
}
.overtime_rule_type {
  margin-top: 4px;
  height: 40px;
  border-bottom: 1px solid #cfd4e8;
}
::v-deep .overtime_rule_type2 .el-select {
  width: 80px;
  height: 30px;
  margin: 0 11px;
  font-size: 14px;
}
::v-deep .overtime_rule_type2 .el-input {
  width: 80px;
  height: 30px;
}
::v-deep .overtime_rule_type2 .el-select .el-input__inner {
  width: 100%;
  height: 100%;
  color: #1a1a1a;
}
.overtime_rule_type2 {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 20px;
  color: #1a1a1a;
}
.overtime_rule_type_add {
  width: 18px;
  height: 18px;
  margin-left: 11px;
  cursor: pointer;
}
::v-deep .error .el-input__inner {
  border-color: red;
}
.error_tips {
  color: red;
}
.rest_period {
  display: flex;
  font-size: 15px;
  align-items: center;
  color: #1a1a1a;
  margin-top: 24px;
}
.rest_error {
  color: red;
}
.rest_period_title {
  margin-right: 11px;
}
.rest_period_heng {
  margin: 0 10px;
}
.bt {
  margin-bottom: 20px;
}
.weekday {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #cfd4e8;
  font-size: 15px;
  color: #1a1a1a;
}
::v-deep .weekday .el-input {
  width: 60px;
  height: 36px;
  margin: 0 10px;
}
::v-deep .weekday .el-input__inner {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
}
.weekday_single {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #1a1a1a;
}
.weekday_single2 {
  display: flex;
  align-items: center;
  color: #1a1a1a;
}
.weekday_tips {
  color: rgba(26, 26, 26, 0.6);
  margin-left: 16px;
}
.restday {
  width: 100%;
  height: 142px;
  padding-top: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #cfd4e8;
}
::v-deep .restday .el-input {
  width: 60px;
  height: 36px;
  margin: 0 10px;
}
::v-deep .restday .el-input__inner {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
}
/* 班次 */
.attendance_all {
  width: 100%;
  height: 626px;
  overflow-y: scroll;
  background: #ffffff;
}
.attendance_all::-webkit-scrollbar {
  display: none;
}
.attendance_all_header {
  width: 100%;
  height: 63px;
  border-bottom: 1px solid #cfd4e8;
  display: flex;
  align-items: center;
}
.attendance_all_header_left {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
.attendance_all_header_right {
  width: 71px;
  height: 100%;
  line-height: 63px;
  text-align: center;
  border-left: 1px solid #cfd4e8;
  box-sizing: border-box;
}
.attendance_all_header_right2 {
  width: 71px;
  height: 100%;
  line-height: 280px;
  text-align: center;
  border-left: 1px solid #cfd4e8;
  box-sizing: border-box;
  font-size: 15px;
  cursor: pointer;
}
.disable {
  color: rgba(26, 26, 26, 0.3);
}
.nodisable {
  color: #0058ff;
}
.workhour {
  width: 204px;
}
.workrule {
  width: 461px;
}
.afterwordrule {
  flex: 1;
}
.workhour_time {
  display: flex;
  align-items: center;
  border: 1px solid #d5d7e3;
  border-radius: 4px;
  width: 164px;
}
::v-deep .el-select {
  width: 76px;
}
::v-deep .workhour_time .el-input__inner {
  border: none;
  width: 100%;
}
::v-deep .workhour_time .el-date-editor.el-input {
  width: 88px;
}
::v-deep .workhour_time_specific .el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}
.workhour_time_up {
  margin-top: 29px;
}
.workhour_time_down {
  margin-top: 60px;
}
.mt {
  margin-top: 29px;
}
.mt2 {
  margin-top: 29px;
  flex: 1;
}
.mq {
  margin: 15px 0 22px;
}
::v-deep .workhour_time .el-form-item__content {
  line-height: 20px;
}
.overtime_rule_type {
  margin-top: 4px;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #cfd4e8;
}
::v-deep .overtime_rule_type2 .el-select {
  width: 80px;
  height: 30px;
  margin: 0 11px;
  font-size: 14px;
  line-height: auto;
}
::v-deep .overtime_rule_type2 .el-input {
  width: 80px;
  height: 30px;
}
::v-deep .overtime_rule_type2 .el-select .el-input__inner {
  width: 100%;
  height: 100%;
  color: #1a1a1a;
}
.overtime_rule_type2 {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 20px;
  color: #1a1a1a;
}
.overtime_rule_type_add {
  width: 18px;
  height: 18px;
  margin-left: 11px;
  cursor: pointer;
}
::v-deep .error .el-input__inner {
  border-color: red;
}
.error_tips {
  color: red;
}
.rest_period {
  display: flex;
  font-size: 15px;
  align-items: center;
  color: #1a1a1a;
  margin-top: 24px;
}
::v-deep .rest_period_select_time .el-input {
  width: 80px;
  height: 30px;
  display: flex;
}
::v-deep .rest_period_select_time .el-input__inner {
  width: 100%;
  height: 100%;
  padding: 0;
  border-left: none;
  text-align: center;
}
::v-deep .rest_period_select_time .el-icon-time:before {
  content: "";
}
::v-deep .rest_period_select_date .el-input {
  width: 80px;
  height: 30px;
  display: flex;
}
::v-deep .rest_period_select_date .el-input__inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.rest_period_title {
  margin-right: 11px;
}
.rest_period_heng {
  margin: 0 10px;
}
.attendance_add {
  width: 108px;
  height: 35px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #0058ff;
  margin-right: 20px;
  justify-content: center;
  cursor: pointer;
}
.attendance_add_icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.error_tips {
  color: red;
  width: 164px;
  line-height: 20px;
}
.time_list {
  display: flex;
  width: 370px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time_single {
  flex: 0 0 116px;
  width: 116px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #1a1a1a;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
.rest_period_select {
  display: flex;
  align-items: center;
}
.timelimitation {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.timelimitation_left {
  font-size: 15px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
}
.timelimitation_left_icon {
  width: 16px;
  margin-left: 4px;
  height: 16px;
  margin-right: 10px;
  cursor: pointer;
}
.timelimitation_right {
  display: flex;
  align-items: center;
}
::v-deep .timelimitation_right .el-input {
  width: 85px !important;
  height: 36px;
}
::v-deep .timelimitation_right .el-date-editor {
  width: 120px !important;
  height: 36px;
  margin-left: 10px;
}
::v-deep .timelimitation_right .el-input__inner {
  height: 36px;
}
.zhi {
  margin: 0 12px;
}
.weekday_num_tips {
  margin-top: 20px;
  color: #1a1a1a;
  font-size: 16px;
}
.weekday_num_tips span {
  margin-left: 10px;
  color: #0058ff;
  cursor: pointer;
}
.device_search_h {
  width: 413px;
}
.title_text {
  font-size: 18px;
}
.title_text span {
  font-size: 15px;
  color: red;
}
</style>